import { MouseEventHandler, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import Button, { ColorStyle } from "../Button/Button";
import { borders, colors } from "../../theme";
import { FaTimesCircle } from "react-icons/fa";

const StyledDialog = styled.dialog`
	${(p) =>
		css`
			position: fixed;
			box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
			background-color: ${p.theme.colorBackgroundLightLight};
			border-radius: ${borders.fields.radius};
			max-height: 90%;
			max-width: 90%;
			width: 700px;

			.modal-container {
				width: 700px;
				max-width: 100%;
			}

			&::backdrop {
				position: fixed;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background-color: rgba(51, 51, 51, 0.3);
				backdrop-filter: blur(1px);
				animation: none;
			}

			&:focus {
				outline: none;
			}

			.modal-container {
				display: flex;
				flex-direction: column;
				gap: 30px;
				padding: 20px;
			}

			.modal-label {
				margin: 0 auto;
				text-align: center;
				font-size: ${p.theme.h3Size};
				font-weight: ${p.theme.fontSemiBold};
				color: ${p.theme.colorPrimary};
				max-width: 80%;
			}

			.close-modal {
				position: absolute;
				top: 50%;
				right: 0;
				width: 30px;
				height: 30px;
				color: ${p.theme.colorPrimary};
				cursor: pointer;
				transform: translateY(-50%);
			}

			@media only screen and (min-width: ${p.theme.sm}) {
				.modal-container {
					padding: 40px;
				}
			}
		`};
`;

const Modal = (props: ModalProps) => {
	const { className, children, buttonLabel, modalLabel, open, setOpen } = props;
	const modalRef = useRef<HTMLDialogElement | null>(null);
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		if (modalRef && modalRef.current) {
			if (isOpen) {
				modalRef.current.showModal();
				setOpen !== undefined && setOpen(true);
			} else {
				modalRef.current.close();
				setOpen !== undefined && setOpen(false);
			}
		}
	}, [isOpen]);

	const hideOnClickBackdrop = (event: React.MouseEvent<HTMLDialogElement>) => {
		if (modalRef && modalRef.current && event.target === modalRef.current) {
			setIsOpen(false);
		}
	};

	useEffect(() => {
		if (open !== undefined) {
			setIsOpen(open);
		}
	}, [open]);

	return (
		<>
			{buttonLabel && (
				<Button
					type="button"
					onClick={() => setIsOpen(true)}
					buttonstyle={ColorStyle.Secondary}
					aria-label={`${buttonLabel} for ${modalLabel}`}
				>
					{buttonLabel}
				</Button>
			)}

			<StyledDialog
				ref={modalRef}
				className={className}
				onClick={hideOnClickBackdrop}
			>
				<div className="modal-container">
					<div className="flex relative mb-[1rem]">
						<span className="modal-label">{modalLabel}</span>
						<FaTimesCircle
							className="close-modal"
							onClick={() => setIsOpen(false)}
						/>
					</div>

					{children}

					{buttonLabel && (
						<Button
							className="mx-auto"
							type="button"
							onClick={() => setIsOpen(false)}
							buttonstyle={ColorStyle.Secondary}
						>
							Upload Files
						</Button>
					)}
				</div>
			</StyledDialog>
		</>
	);
};

export default Modal;

interface ModalProps {
	className?: string;
	children: React.ReactNode;
	modalLabel: string;
	buttonLabel?: string;
	open?: boolean;
	setOpen?(open: boolean): void; // keep modal and external state in sync
}
