import { useNavigate } from "react-router-dom";
import logo from "../../assets/NMMIP_logo.webp";
import Button from "../../components/Button/Button";

const Home = () => {
	const navigate = useNavigate();
	return (
		<div className="flex flex-col gap-[1rem] max-w-md mx-auto bg-white p-8 rounded-lg shadow-lg">
			<img
				src={logo}
				alt="NMMIP Logo"
				className="w-1/2 mx-auto cursor-pointer"
				onClick={() => (window.location.href = "https://nmmip.org/")}
			/>
			<h1 className="mx-auto text-center text-[24px]">
				New Mexico Medical Insurance Pool (NMMIP)
			</h1>
			<p>
				Please continue your application for coverage or recertify for the Low
				Income Premium Program (LIPP). If you are 65 or older and are eligible
				for Medicare, you can recertify for LIPP (Medicare).
			</p>
			<Button
				type="button"
				className="mx-auto !w-full justify-center"
				onClick={() => navigate("/healthcare-coverage-form")}
			>
				Continue to Application for Coverage
			</Button>
			<Button
				type="button"
				className="mx-auto !w-full justify-center"
				onClick={() => navigate("/lipp-form/")}
			>
				Recertify for LIPP
			</Button>
			<Button
				type="button"
				className="mx-auto !w-full justify-center"
				onClick={() => navigate("/lipp-form/medicare-carve-out")}
			>
				Recertify for LIPP (Medicare)
			</Button>
		</div>
	);
};

export default Home;
