import { useFormikContext } from "formik";
import { useNavigate } from "react-router-dom";
import { BlueLegendHeading } from "../../../components/Legend/Legend";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import Button from "../../../components/Button/Button";

const Submission = () => {
	return (
		<>
			<strong>
				You will be notified once a decision regarding your application has been
				made.
			</strong>
			<p>
				Please note that once your application is submitted, the information
				cannot be changed. For assistance, contact us at 1-866-306-1882 or email{" "}
				<span className="underline">
					NMMIP_Eligibility@90degreebenefits.com
				</span>
				.
			</p>
		</>
	);
};

export default Submission;
