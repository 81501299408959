import styled, { css } from "styled-components";

const StyledBrokerPopup = styled.div`
	${(p) => css`
		position: fixed;
		right: 100px;
		bottom: 0;
		width: 200px;
		padding: 10px 20px;
		border-radius: 10px 10px 0 0;
		color: ${p.theme.colorCopyLightLight};
		font-weight: 600;
		background: #2f5194;
		cursor: pointer;
		z-index: 99;

		span {
			line-height: 1rem;
		}

		@media only screen and (max-width: ${p.theme.sm}) {
			right: 30px;
			width: fit-content;
			padding: 7.5px 15px;
			span {
				font-size: ${p.theme.xxSmallSize};
			}
		}
	`}
`;

const BrokerPopup = (props: BrokerPopupProps) => {
	return props.isOpen ? (
		<StyledBrokerPopup
			onClick={() =>
				window.open(
					"https://nmmip.org/wp-content/uploads/2024/05/Broker-List-Updated-5_7_24.pdf",
					"_blank"
				)
			}
		>
			<span>
				Need Help? <br />
				Find a Broker
			</span>
		</StyledBrokerPopup>
	) : (
		<></>
	);
};

interface BrokerPopupProps {
	isOpen: boolean;
}

export default BrokerPopup;
