import Dropdown from "../../../components/FormFields/Dropdown";
import CheckBox from "../../../components/FormFields/CheckBox";
import TextField from "../../../components/FormFields/Textfield";
import Radio from "../../../components/FormFields/Radio";
import DateField from "../../../components/FormFields/DateField";
import Signature from "../../../components/FormFields/Signature";
import FileUpload from "../../../components/FormFields/FileUpload";
import Modal from "../../../components/Modal/Modal";
import Button, { ColorStyle } from "../../../components/Button/Button";
import NoticeBanner from "../../../components/NoticeBanner/NoticeBanner";
import * as Yup from "yup";
import FormGrid from "../../../components/FormGrid/FormGrid";
import { useFormikContext } from "formik";
import { getNestedValue } from "../../../utils/getNestedValue";
import { useEffect, useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import { normalizeDate } from "../../../utils/normalizeDate";
import { PaymentTypes } from "../FormSchema/formInitialValues";
import StyledList from "../../../components/Lists/Lists";

const requiredWarning = "This field is required.";

export const schema = {
	values: {
		initials: "",
	},
	validation: Yup.object().shape({
		initials: Yup.string().required(requiredWarning),
	}),
};

const Initials = () => {
	const { errors, values } = useFormikContext<any>();

	return (
		<>
			<h2>By initialing below, I acknowledge the following:</h2>
			<StyledList>
				<ul>
					<li>
						I am applying to the New Mexico Medical Insurance Pool (NMMIP) for
						an individual policy covering medical, surgical, prescription, and
						hospital insurance.
					</li>
					<li>
						My coverage will start on the first of the month following receipt
						of my application by NMMIP unless I am eligible for HIPAA coverage
						or continuation. If eligible for HIPAA coverage or continuation, my
						coverage will start the date that my prior group coverage ended.
					</li>
					<li>
						I will be responsible for paying premiums from my effective date
						forward.
					</li>
					<li>
						I affirm that all answers provided in this application are complete
						and accurate.
					</li>
					<li>
						No coverage will be effective until this application is accepted and
						approved, and the full initial premium has been paid.
					</li>
					<li>
						I have a ten-day period in which I can examine and choose to return
						the contract to have my premium refunded. If I receive services
						within that ten-day period, I must pay for those services.
					</li>
				</ul>
			</StyledList>
			<h2>
				Initial below to indicate that you have read and understand the
				information listed above.
			</h2>
			<TextField
				className="max-w-[250px]"
				type="text"
				name="initials"
				label="Initials"
				required
				autoComplete="off"
			/>
			<p>
				A parent/legal guardian/personal representative must initial if the
				applicant is under 18 years of age or legally incompetent.
			</p>
		</>
	);
};

export default Initials;
