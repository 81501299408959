import * as Yup from "yup";
import Radio from "../../../components/FormFields/Radio";
import FormGrid from "../../../components/FormGrid/FormGrid";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import Modal from "../../../components/Modal/Modal";
import NoticeBanner from "../../../components/NoticeBanner/NoticeBanner";
import Button, { ColorStyle } from "../../../components/Button/Button";
import FileUpload, {
	UploadedFileList,
} from "../../../components/FormFields/FileUpload";
import { FaArrowRight } from "react-icons/fa";
import DateField from "../../../components/FormFields/DateField";
import { useFormContext } from "../../../components/FormContext/FormContext";

const requiredWarning = "This field is required.";

export const schema = {
	values: {
		isUnder65AndDisabled: "",
		haveMedicarePartAandB: "",
		partAEffectiveDate: "",
		partBEffectiveDate: "",
	},
	validation: Yup.object().shape({
		isUnder65AndDisabled: Yup.string().required(requiredWarning),
		haveMedicarePartAandB: Yup.string().required(requiredWarning),
		partAEffectiveDate: Yup.string()
			.nullable()
			.test({
				name: "partAEffectiveDateRequired",
				message: requiredWarning,
				test(value, ctx) {
					const { haveMedicarePartAandB } = ctx.parent;
					return haveMedicarePartAandB === "Yes"
						? value
							? true
							: false
						: true;
				},
			}),
		partBEffectiveDate: Yup.string()
			.nullable()
			.test({
				name: "partBEffectiveDateRequired",
				message: requiredWarning,
				test(value, ctx) {
					const { haveMedicarePartAandB } = ctx.parent;
					return haveMedicarePartAandB === "Yes"
						? value
							? true
							: false
						: true;
				},
			}),
	}),
};

const QualifyingConditions = () => {
	const { values, errors } = useFormikContext<any>();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const isUnder65AndDisabled = values["isUnder65AndDisabled"];
	const haveMedicarePartAandB = values["haveMedicarePartAandB"];
	const { formId } = useFormContext(); // Get formId from context

	useEffect(() => {
		if (isUnder65AndDisabled && isUnder65AndDisabled === "No") {
			setIsModalOpen(true);
		} else {
			setIsModalOpen(false);
		}
	}, [isUnder65AndDisabled]);

	useEffect(() => {
		if (haveMedicarePartAandB && haveMedicarePartAandB === "No") {
			setIsModalOpen(true);
		} else {
			setIsModalOpen(false);
		}
	}, [haveMedicarePartAandB]);

	return (
		<>
			<p className="leading-[24px]">
				To determine if you meet the eligibility criteria select YES or NO for
				every question below and upload an award letter or Medicare Card.
				<br />
				<br />
				If you answer "no" to any of the following questions, you may not be
				eligible for NMMIP coverage.
			</p>

			<Radio
				className="!max-w-[700px]"
				name="isUnder65AndDisabled"
				label="I am under 65 years of age and enrolled in Medicare due to a disability."
				options={[{ label: "Yes" }, { label: "No" }]}
				required
			/>
			<Radio
				className="!max-w-[700px]"
				name="haveMedicarePartAandB"
				label="I have Medicare parts A and Parts B."
				options={[{ label: "Yes" }, { label: "No" }]}
				required
			/>

			{haveMedicarePartAandB === "Yes" && (
				<>
					<DateField
						className="max-w-[300px]"
						name="partAEffectiveDate"
						label="Part A Effective Date"
						placeholder="Part A Effective Date"
						required
					/>
					<DateField
						className="max-w-[300px]"
						name="partBEffectiveDate"
						label="Part B Effective Date"
						placeholder="Part B Effective Date"
						required
					/>
				</>
			)}
			<Modal
				modalLabel="Qualifying Conditions"
				open={isModalOpen}
				setOpen={setIsModalOpen}
			>
				<NoticeBanner bannerstyle={ColorStyle.Primary}>
					To be eligible for the Medicare Carve-Out plan, you must be under age
					65 and be enrolled in Medicare Parts A and B due to disability.
				</NoticeBanner>
				<NoticeBanner bannerstyle={ColorStyle.Secondary}>
					Your response may indicate that you are not eligible for coverage.
					However, you can continue to submit an application for review.
				</NoticeBanner>
				<Button
					className="mx-auto"
					type="button"
					onClick={() => setIsModalOpen(false)}
				>
					Continue
					<FaArrowRight />
				</Button>
			</Modal>

			<Modal
				modalLabel="Award Letter or Medicare Card "
				buttonLabel="Upload Documentation"
			>
				<NoticeBanner bannerstyle={ColorStyle.Primary}>
					A copy of your award letter or Medicare Card is required with this
					application.
				</NoticeBanner>
				<NoticeBanner bannerstyle={ColorStyle.Secondary}>
					If you don't have the documentation electronically, please mail it to
					NMMIP upon completing this application.
				</NoticeBanner>
				<FileUpload
					name="documentation.awardLetterOrMedicareCard"
					label="Upload Files"
					documentationType="awardLetterOrMedicareCard"
				/>
			</Modal>

			<div className="max-w-[500px]">
				<UploadedFileList
					formId={formId}
					fieldName={"documentation.awardLetterOrMedicareCard"}
					documentationType="awardLetterOrMedicareCard"
				/>
			</div>
		</>
	);
};

export default QualifyingConditions;
