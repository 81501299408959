import * as Yup from "yup";
import Radio from "../../../components/FormFields/Radio";

const requiredWarning = "This field is required.";

export const schema = {
	values: {
		isOver65: "",
	},
	validation: Yup.object().shape({
		isOver65: Yup.string().required(requiredWarning),
	}),
};

const Medicare = () => {
	return (
		<Radio
			className="max-w-[475px]"
			name="isOver65"
			label="I am 65 or older and am eligible for Medicare."
			options={[{ label: "Yes" }, { label: "No" }]}
			required
		/>
	);
};

export default Medicare;
