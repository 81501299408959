import { normalizeDate } from "./normalizeDate";

export function isDateWithin95Days(dateToCheck: string) {
	// Get today's date
	const today = normalizeDate(new Date());

	// Create a date object representing 95 days BEFORE today
	const pastDate = new Date(today);
	pastDate.setDate(today.getDate() - 95);
	pastDate.setHours(0, 0, 0, 0);

	// Convert dateToCheck to a Date object if it's not already
	const checkDate = normalizeDate(new Date(dateToCheck));

	// Check if dateToCheck is within 95 days
	return checkDate >= pastDate && checkDate <= today;
}

// check if today is within 95 days after the startDate
export function isDateWithin95DaysAfter(
	startDate: string,
	priorCoverageEndDate: string
) {
	// Get today's date
	const newCoverageStartDate = normalizeDate(new Date(startDate));

	// Convert checkDate to a Date object if it's not already
	const checkDate = normalizeDate(new Date(priorCoverageEndDate));

	// Create a date object representing 95 days AFTER priorCoverageEndDate
	const futureDate = new Date(checkDate);
	futureDate.setDate(checkDate.getDate() + 95);
	futureDate.setHours(0, 0, 0, 0);

	// Check if futureDate is within 95 days AFTER
	return (
		newCoverageStartDate >= checkDate && newCoverageStartDate <= futureDate
	);
}
