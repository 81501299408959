import * as Yup from "yup";
import Radio from "../../../components/FormFields/Radio";

const requiredWarning = "This field is required.";

export const schema = {
	values: {
		isNewborn: "",
	},
	validation: Yup.object().shape({
		isNewborn: Yup.string().required(requiredWarning),
	}),
};

const Newborn = () => {
	return (
		<Radio
			className="max-w-[700px]"
			name="isNewborn"
			label="I am an existing member and need to add a newborn to my coverage."
			options={[{ label: "Yes" }, { label: "No" }]}
			required
		/>
	);
};

export default Newborn;
