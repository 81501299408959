import Dropdown from "../../../components/FormFields/Dropdown";
import CheckBox from "../../../components/FormFields/CheckBox";
import TextField from "../../../components/FormFields/Textfield";
import Radio from "../../../components/FormFields/Radio";
import DateField from "../../../components/FormFields/DateField";
import Signature from "../../../components/FormFields/Signature";
import FileUpload from "../../../components/FormFields/FileUpload";
import Modal from "../../../components/Modal/Modal";
import Button, { ColorStyle } from "../../../components/Button/Button";
import NoticeBanner from "../../../components/NoticeBanner/NoticeBanner";
import * as Yup from "yup";
import FormGrid from "../../../components/FormGrid/FormGrid";
import { useFormikContext } from "formik";
import { getNestedValue } from "../../../utils/getNestedValue";
import { useEffect, useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import { normalizeDate } from "../../../utils/normalizeDate";

const requiredWarning = "This field is required.";

export const schema = {
	values: {
		isInsuranceAgent: "",
	},
	validation: Yup.object().shape({
		isInsuranceAgent: Yup.string().required(requiredWarning),
	}),
};

const InsuranceAgent = () => {
	return (
		<>
			<Button
				type="button"
				href={
					"https://nmmip.org/wp-content/uploads/2024/05/Broker-List-Updated-5_7_24.pdf"
				}
			>
				Find a Broker
			</Button>
			<Radio
				className="!max-w-[850px]"
				name="isInsuranceAgent"
				label="Is an Agent, State Agency/Foundation, or Third Party Sponsor assisting you with completing this application? "
				options={[{ label: "Yes" }, { label: "No" }]}
				required
			/>
		</>
	);
};

export default InsuranceAgent;
