import SignatureCanvas from "react-signature-canvas";
import styled, { css, useTheme } from "styled-components";
import { FaEraser } from "react-icons/fa";
import { useEffect, useRef, useState } from "react";
import { borders } from "../../theme";
import { FieldType } from "./fieldTypes";
import { Asterisk, Error } from "./Field";
import { useField, useFormikContext } from "formik";
import { useFormContext } from "../FormContext/FormContext";

const StyledSignature = styled.div<{ error: boolean }>`
	${(p) =>
		css`
			display: flex;
			flex-direction: column;
			canvas {
				border: solid ${borders.fields.thickness}
					${p.error ? p.theme.colorSecondary : p.theme.colorPrimary};
				border-radius: ${borders.fields.radius};
			}
		`}
`;

const Signature = (props: SignatureProps) => {
	const theme = useTheme();
	const ref = useRef<any>();
	const { name, label, required, width, height, signatureType } = props;
	const { values, setFieldValue } = useFormikContext<any>();
	const [field, meta, helpers] = useField(props);
	const { contactInfo, formId } = useFormContext();
	const [isSignatureLoaded, setIsSignatureLoaded] = useState(false);
	const [uploadTimeout, setUploadTimeout] = useState<NodeJS.Timeout | null>(
		null
	);

	// Fetch signature from S3 and set it in the signature canvas
	const loadSignature = async () => {
		try {
			const response = await fetch(
				`/api/signature/${formId}/${signatureType}`,
				{
					method: "GET",
				}
			);
			const result = await response.json();
			if (result.success && result.signatureDataUrl) {
				const base64Signature = result.signatureDataUrl;
				ref.current.fromDataURL(base64Signature); // Load signature into canvas
				setFieldValue(name, base64Signature); // Update Formik with the signature
				setIsSignatureLoaded(true);
			} else {
			}
		} catch (error) {
			console.error("Error fetching signature:", error);
		}
	};

	const clear = () => {
		if (ref && ref.current) {
			ref.current.clear();
			props.onSign && props.onSign("");
			setFieldValue(name, "");
		}
	};

	const capture = async () => {
		if (ref && ref.current) {
			// Clear any existing timeout to prevent multiple uploads
			if (uploadTimeout) {
				clearTimeout(uploadTimeout);
			}

			// Set a new timeout for 2 seconds after drawing ends
			const newTimeout = setTimeout(async () => {
				const signatureUrl = ref.current.toDataURL("image/png");
				setFieldValue(name, signatureUrl);

				// Upload signature to S3
				const response = await fetch("/api/signature/upload", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						formId,
						contactInfo,
						signatureType: signatureType,
						signatureDataUrl: signatureUrl,
					}),
				});

				const result = await response.json();
				if (result.success) {
				} else {
					console.error("Failed to upload signature:", result.message);
				}
			}, 1500); // 1.5-second delay before uploading

			// Save the timeout ID so it can be cleared if the user continues drawing
			setUploadTimeout(newTimeout);
		}
	};

	useEffect(() => {
		if (!isSignatureLoaded) {
			loadSignature();
		}
	}, []);

	// clear signature field if it doesn't exist in formikProps
	useEffect(() => {
		if (!values[name]) clear();
	}, [values[name]]);

	useEffect(() => {}, []);

	return (
		<StyledSignature
			className="w-fit"
			error={meta.touched && meta.error ? true : false}
		>
			<label>
				{label}
				{required && <Asterisk />}
			</label>

			<SignatureCanvas
				penColor={theme.colorCopyDarkDark}
				canvasProps={{
					width: width || (window.innerWidth > 500 ? 500 : 300),
					height: height || 200,
				}}
				backgroundColor={theme.colorBackgroundLightLight}
				ref={ref}
				onEnd={capture}
			/>
			<span
				className="flex items-center gap-[5px] mt-[5px] w-fit ml-auto cursor-pointer select-none"
				onClick={clear}
			>
				Clear Signature <FaEraser color={theme.colorCopyDark} />
			</span>

			<Error touched={meta.touched} error={meta.error} />
		</StyledSignature>
	);
};

export default Signature;

interface SignatureProps extends FieldType {
	width?: string;
	height?: string;
	signatureType?: string;
	onSign?(signatureUrl: string): void;
}
