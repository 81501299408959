// section intro
import { schema as introSchema } from "../SectionIntro/Intro";
import { schema as startSchema } from "../SectionIntro/Start";
import { schema as medicareSchema } from "../SectionIntro/Medicare";
import { schema as newbornSchema } from "../SectionIntro/Newborn";

// section 1
import { schema as basicInfoSchema } from "../Section1/BasicInfo";
import { schema as applicantInfoSchema } from "../Section1/ApplicantInfo";

// section 2
import { schema as coverageDetailsSchema } from "../Section2/CoverageDetails";

// section 3
import { schema as proofOfEliigiblitySchema } from "../Section3/ProofOfEligibility";
import { schema as hipaaSchema } from "../Section3/HIPAA";
import { schema as generalExclusions1Schema } from "../Section3/GeneralExclusions1";
import { schema as generalExclusions2Schema } from "../Section3/GeneralExclusions2";

// section 4
import { schema as medicalInformationSchema } from "../Section4/MedicalInformation";
import { schema as physicianSchema } from "../Section4/Physician";
import { schema as medicalConditionsSchema } from "../Section4/MedicalConditions";

// section 5
import { schema as insuranceAgentSchema } from "../Section5/InsuranceAgent";
import { schema as agentInformationSchema } from "../Section5/AgentInformation";

// section 6
import { schema as premiumPaymmentSchema } from "../Section6/PremiumPayment";

// section documentation
import { schema as documentationSchema } from "../SectionDocumentation/Documentation";

// section 7
import { schema as initialsSchema } from "../Section7/Initials";
import { schema as affirmationSchema } from "../Section7/Affirmation";
import { SelectOption } from "../../../components/FormFields/Dropdown";

export const USStates = [
	{ label: "Alabama" },
	{ label: "Alaska" },
	{ label: "American Samoa" },
	{ label: "Arizona" },
	{ label: "Arkansas" },
	{ label: "California" },
	{ label: "Colorado" },
	{ label: "Connecticut" },
	{ label: "Delaware" },
	{ label: "District of Columbia" },
	{ label: "Florida" },
	{ label: "Georgia" },
	{ label: "Guam" },
	{ label: "Hawaii" },
	{ label: "Idaho" },
	{ label: "Illinois" },
	{ label: "Indiana" },
	{ label: "Iowa" },
	{ label: "Kansas" },
	{ label: "Kentucky" },
	{ label: "Louisiana" },
	{ label: "Maine" },
	{ label: "Maryland" },
	{ label: "Massachusetts" },
	{ label: "Michigan" },
	{ label: "Minnesota" },
	{ label: "Minor Outlying Islands" },
	{ label: "Mississippi" },
	{ label: "Missouri" },
	{ label: "Montana" },
	{ label: "Nebraska" },
	{ label: "Nevada" },
	{ label: "New Hampshire" },
	{ label: "New Jersey" },
	{ label: "New Mexico" },
	{ label: "New York" },
	{ label: "North Carolina" },
	{ label: "North Dakota" },
	{ label: "Northern Mariana Islands" },
	{ label: "Ohio" },
	{ label: "Oklahoma" },
	{ label: "Oregon" },
	{ label: "Pennsylvania" },
	{ label: "Puerto Rico" },
	{ label: "Rhode Island" },
	{ label: "South Carolina" },
	{ label: "South Dakota" },
	{ label: "Tennessee" },
	{ label: "Texas" },
	{ label: "U.S. Virgin Islands" },
	{ label: "Utah" },
	{ label: "Vermont" },
	{ label: "Virginia" },
	{ label: "Washington" },
	{ label: "West Virginia" },
	{ label: "Wisconsin" },
	{ label: "Wyoming" },
];

export const MonthsOfYear = [
	{ label: "January" },
	{ label: "February" },
	{ label: "March" },
	{ label: "April" },
	{ label: "May" },
	{ label: "June" },
	{ label: "July" },
	{ label: "August" },
	{ label: "September" },
	{ label: "October" },
	{ label: "November" },
	{ label: "December" },
];

export const Years = (
	showPreviousYear: boolean = false,
	showNextYear: boolean = false
): SelectOption[] => {
	const currentYear = new Date().getFullYear();
	return [
		...(showPreviousYear ? [{ label: String(currentYear - 1) }] : []),
		{ label: String(currentYear) },
		...(showNextYear ? [{ label: String(currentYear + 1) }] : []),
	];
};

export const DebitWithdrawlDate = [{ label: "First" }, { label: "Fifteenth" }];

export const DeductibleAmounts = [
	{ label: "$500" },
	{ label: "$1000" },
	{ label: "$2000" },
	{ label: "$5000" },
];

export const PaymentTypes = [
	{ label: "Money Order or Check" },
	{ label: "One-Time ACH" },
	{ label: "Monthly ACH" },
];

export const BankAccountTypes = [{ label: "Savings" }, { label: "Checking" }];

export const MedicalConditionOptions = [
	{ label: "Artificial Heart Valve" },
	{ label: "Cancer" },
	{ label: "Coronary Artery Disease" },
	{ label: "Cerebral Palsy" },
	{ label: "Cirrhosis of Liver" },
	{ label: "Cystic Fibrosis" },
	{ label: "Diabetes" },
	{ label: "Multiple Sclerosis" },
	{ label: "Hepatitis C (Active)" },
	{ label: "Kidney Failure" },
	{ label: "Leukemia" },
	{ label: "Parkinson's Disease" },
	{ label: "Respiratory Disease" },
	{ label: "ESRD" },
	{ label: "Stroke" },
	{ label: "Organ Transplant" },
	{ label: "Other" },
];

export const AgentTypeOptions = [
	{ label: "Agent/Broker" },
	{ label: "State Agency/Foundation" },
	{ label: "Third Party Sponsor" },
];

type Schema = {
	values: Record<string, any>;
	validation: any;
};

export const schemas: Record<string, Schema> = {
	startSchema,
	medicareSchema,
	newbornSchema,
	basicInfoSchema,
	applicantInfoSchema,
	proofOfEliigiblitySchema,
	hipaaSchema,
	generalExclusions1Schema,
	generalExclusions2Schema,
	coverageDetailsSchema,
	medicalInformationSchema,
	physicianSchema,
	medicalConditionsSchema,
	insuranceAgentSchema,
	agentInformationSchema,
	premiumPaymmentSchema,
	documentationSchema,
	initialsSchema,
	affirmationSchema,
};

export const healthCareFormValues = {
	...startSchema.values,
	...medicareSchema.values,
	...newbornSchema.values,
	...basicInfoSchema.values,
	...applicantInfoSchema.values,
	...proofOfEliigiblitySchema.values,
	...hipaaSchema.values,
	...generalExclusions1Schema.values,
	...generalExclusions2Schema.values,
	...coverageDetailsSchema.values,
	...medicalInformationSchema.values,
	...physicianSchema.values,
	...medicalConditionsSchema.values,
	...insuranceAgentSchema.values,
	...agentInformationSchema.values,
	...premiumPaymmentSchema.values,
	...documentationSchema.values,
	...initialsSchema.values,
	...affirmationSchema.values,
};

export const healthCareFormSchema = [
	introSchema.validation,
	startSchema.validation,
	medicareSchema.validation,
	newbornSchema.validation,
	basicInfoSchema.validation,
	applicantInfoSchema.validation,
	proofOfEliigiblitySchema.validation,
	hipaaSchema.validation,
	generalExclusions1Schema.validation,
	generalExclusions2Schema.validation,
	coverageDetailsSchema.validation,
	medicalInformationSchema.validation,
	physicianSchema.validation,
	medicalConditionsSchema.validation,
	insuranceAgentSchema.validation,
	agentInformationSchema.validation,
	premiumPaymmentSchema.validation,
	documentationSchema.validation,
	initialsSchema.validation,
	affirmationSchema.validation,
];
