import { FaTimesCircle } from "react-icons/fa";
import styled, { css } from "styled-components";

export const CloseIcon = styled(FaTimesCircle)`
	${(p) => css`
		flex: none;
		color: ${p.theme.colorPrimary};
		width: 20px;
		height: 20px;
		min-width: 20px;
		cursor: pointer;
	`}
`;
