import Dropdown from "../../../components/FormFields/Dropdown";
import CheckBox from "../../../components/FormFields/CheckBox";
import TextField from "../../../components/FormFields/Textfield";
import Radio from "../../../components/FormFields/Radio";
import DateField from "../../../components/FormFields/DateField";
import Signature from "../../../components/FormFields/Signature";
import FileUpload from "../../../components/FormFields/FileUpload";
import Modal from "../../../components/Modal/Modal";
import Button, { ColorStyle } from "../../../components/Button/Button";
import NoticeBanner from "../../../components/NoticeBanner/NoticeBanner";
import * as Yup from "yup";
import FormGrid from "../../../components/FormGrid/FormGrid";
import { useFormikContext } from "formik";
import { getNestedValue } from "../../../utils/getNestedValue";
import { useEffect, useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import { normalizeDate } from "../../../utils/normalizeDate";

const requiredWarning = "This field is required.";

export const schema = {
	values: {
		usedTabacco: "",
		inpatient: "",
		inpatientHospital: "",
	},
	validation: Yup.object().shape({
		usedTabacco: Yup.string().required(requiredWarning),
		inpatient: Yup.string().required(requiredWarning),
		inpatientHospital: Yup.string()
			.nullable()
			.test({
				name: "inpatient",
				message: requiredWarning,
				test(value, ctx) {
					const { inpatient } = ctx.parent;
					return inpatient === "Yes" ? (value ? true : false) : true;
				},
			}),
	}),
};

const MedicalInformation = () => {
	const { errors, values } = useFormikContext<any>();
	const inpatient = values["inpatient"];
	return (
		<>
			<FormGrid className="flex-col">
				<Radio
					className="!max-w-[850px]"
					name="usedTabacco"
					label="Do you currently use, or have you used tobacco in any form within the past 12 months?"
					options={[{ label: "Yes" }, { label: "No" }]}
					required
				/>
				<Radio
					className="!max-w-[850px]"
					name="inpatient"
					label="Are you currently inpatient at a hospital facility?"
					options={[{ label: "Yes" }, { label: "No" }]}
					required
				/>
				{inpatient === "Yes" && (
					<TextField
						className="!flex-none"
						type="text"
						name="inpatientHospital"
						label="Name of Hospital"
						required
					/>
				)}
			</FormGrid>
		</>
	);
};

export default MedicalInformation;
