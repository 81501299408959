import Button from "../../components/Button/Button";

const PageNotFound = () => {
	return (
		<div className="flex flex-col justify-center items-center gap-[1rem] mx-auto  h-full">
			<h1 className="text-[70px]">404</h1>
			<h1>Oops! Page Not Found</h1>
			<p className="max-w-[600px] text-center">
				It looks like the page you're trying to access is missing or the session
				has expired. To continue, please log back in.
			</p>
			<Button type="button" onClick={() => (window.location.href = "/home")}>
				Log In and Return to Form
			</Button>
		</div>
	);
};

export default PageNotFound;
