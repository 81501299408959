import styled, { css } from "styled-components";
import { borders } from "../../theme";

const StyledButton = styled.button<{
	buttonstyle: ColorStyle;
	disabled?: boolean;
}>`
	${(p) => css`
		width: fit-content;
		color: ${(p) => p.theme.colorCopyLightLight};
		font-size: ${(p) => p.theme.spanSize};
		padding: 14px 18px 14px 18px;
		border-radius: ${borders.fields.radius};
		transition: all 250ms ease;

		${p.buttonstyle === ColorStyle.Primary &&
		css`
			background: ${(p) => p.theme.colorPrimary};
			&:hover {
				background: ${(p) => p.theme.colorSecondary};
			}

			&:focus-visible {
				transition: none;
				outline: 3px solid ${(p) => p.theme.colorSecondary};
			}
		`}
		${p.buttonstyle === ColorStyle.Secondary &&
		css`
			background: ${(p) => p.theme.colorSecondary};
			&:hover {
				background: ${(p) => p.theme.colorPrimary};
			}

			&:focus-visible {
				transition: none;
				outline: 3px solid ${(p) => p.theme.colorPrimary};
			}
		`}

		${p.disabled &&
		css`
			background: #102a5d80;

			&:hover {
				background: #102a5d80;
			}
		`}

		&:focus:not(:focus-visible) {
			outline: 0;
			box-shadow: none;
		}
	`}
`;

const Button = (props: ButtonProps) => {
	const { children, onClick, buttonstyle, disabled } = props;

	const handleClick = () => {
		props.onClick && props.onClick();
		props.href && window.open(props.href);
	};
	return (
		<StyledButton
			className={`${
				props.className ? props.className : ""
			} flex gap-[.5em] items-center`}
			buttonstyle={buttonstyle !== undefined ? buttonstyle : ColorStyle.Primary}
			onClick={handleClick}
			disabled={disabled}
		>
			{children}
		</StyledButton>
	);
};

export default Button;

interface ButtonProps {
	className?: string;
	href?: string;
	children?: React.ReactNode;
	onClick?(): void;
	type: "button" | "submit";
	disabled?: boolean;
	buttonstyle?: ColorStyle;
}

export enum ColorStyle {
	Primary,
	Secondary,
}
