import Dropdown from "../../../components/FormFields/Dropdown";
import CheckBox from "../../../components/FormFields/CheckBox";
import TextField from "../../../components/FormFields/Textfield";
import Radio from "../../../components/FormFields/Radio";
import DateField from "../../../components/FormFields/DateField";
import Signature from "../../../components/FormFields/Signature";
import FileUpload from "../../../components/FormFields/FileUpload";
import Modal from "../../../components/Modal/Modal";
import Button, { ColorStyle } from "../../../components/Button/Button";
import NoticeBanner from "../../../components/NoticeBanner/NoticeBanner";
import * as Yup from "yup";
import FormGrid from "../../../components/FormGrid/FormGrid";
import { useFormikContext } from "formik";
import { getNestedValue } from "../../../utils/getNestedValue";
import { useEffect, useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import { normalizeDate } from "../../../utils/normalizeDate";
import { MedicalConditionOptions } from "../../HealthCareCoverageForm/FormSchema/formInitialValues";

const requiredWarning = "This field is required.";

export const schema = {
	values: {
		medicalConditions: [],
		otherCondition: "",
		medicationQuantity: "",
	},
	validation: Yup.object().shape({
		medicationQuantity: Yup.number()
			.required(requiredWarning)
			.min(0, "Quantity cannot be negative"),
	}),
};

const MedicalConditions = () => {
	const { errors, values } = useFormikContext<any>();
	const medicalConditions = values["medicalConditions"];

	return (
		<>
			<CheckBox
				name="medicalConditions"
				label="Medical Conditions"
				options={MedicalConditionOptions}
				required
			/>

			{medicalConditions && medicalConditions.includes("Other") && (
				<>
					<h2>If your medical condition is not listed, please provide:</h2>
					<TextField type="text" name="otherCondition" label="Other" />
				</>
			)}
			<h2>How many medications do you take?</h2>
			<TextField
				className="max-w-[350px]"
				type="number"
				name="medicationQuantity"
				label="List your Quantity"
				required
			/>
		</>
	);
};

export default MedicalConditions;
