import Dropdown from "../../../components/FormFields/Dropdown";
import CheckBox from "../../../components/FormFields/CheckBox";
import TextField from "../../../components/FormFields/Textfield";
import Radio from "../../../components/FormFields/Radio";
import DateField from "../../../components/FormFields/DateField";
import Signature from "../../../components/FormFields/Signature";
import FileUpload, {
	UploadedFileList,
} from "../../../components/FormFields/FileUpload";
import Modal from "../../../components/Modal/Modal";
import Button, { ColorStyle } from "../../../components/Button/Button";
import NoticeBanner from "../../../components/NoticeBanner/NoticeBanner";
import * as Yup from "yup";
import FormGrid from "../../../components/FormGrid/FormGrid";
import { useFormikContext } from "formik";
import { useEffect } from "react";
import { getNestedValue } from "../../../utils/getNestedValue";
import { useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useFormContext } from "../../../components/FormContext/FormContext";
import { USStates } from "../../HealthCareCoverageForm/FormSchema/formInitialValues";

const requiredWarning = "This field is required.";

export const schema = {
	values: {
		residence: {
			address: "",
			city: "",
			state: "New Mexico",
			zipCode: "",
		},
	},
	validation: Yup.object().shape({
		residence: Yup.object().shape({
			address: Yup.string().required(requiredWarning),
			city: Yup.string().required(requiredWarning),
			state: Yup.string()
				.oneOf(["New Mexico"], "You must be a New Mexico resident to apply.")
				.required(requiredWarning),
			zipCode: Yup.string().required(requiredWarning),
		}),
	}),
};

const ApplicantInfo = () => {
	return (
		<>
			<h2>Address Information</h2>
			<FormGrid>
				<TextField
					className="!basis-[400px]"
					type="text"
					name="residence.address"
					label="Residence Address (Physical address required)"
					required
					autoComplete="street-address"
					mobileExtraPadding
				/>
				<TextField
					className="!xs:max-w-[200px]"
					type="text"
					name="residence.city"
					label="City"
					required
				/>
				<Dropdown
					className="!xs:max-w-[200px]"
					name="residence.state"
					label="State"
					options={USStates}
					required
				/>
				<TextField
					className="!xs:max-w-[200px]"
					type="text"
					name="residence.zipCode"
					label="Zip Code"
					required
					maxLength={5}
				/>
			</FormGrid>
		</>
	);
};

export default ApplicantInfo;
