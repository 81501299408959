import styled, { css } from "styled-components";
import { borders } from "../../theme";
import { StyledFieldWrapper } from "../FormFields/Field";

const StyledLegend = styled.legend`
	${(p) => css`
		position: relative;
		width: 100%;
		padding: 24px;
		background: ${p.theme.colorBackgroundFocus};
		border-radius: ${borders.fields.radius};
		border-left: solid 20px ${p.theme.colorSecondary};
		margin-bottom: 30px;
		font-size: ${p.theme.pSize};
		font-weight: ${p.theme.fontSemiBold};

		${StyledFieldWrapper} {
			font-weight: ${p.theme.fontDefaultWeight};
		}

		& > p {
			color: ${p.theme.colorPrimary};
			line-height: 24px;
		}
	`}
`;

export const BlueLegendHeading = styled.p`
	${(p) => css`
		&, span {color: ${p.theme.colorPrimary};
		font-weight: bold;
		margin-bottom: 1rem;
		line-height: 24px;
	`}
`;

export default StyledLegend;
