// section intro
import { schema as introSchema } from "../SectionIntro/Intro";

// section 1
import { schema as applicantInfoSchema } from "../Section1/ApplicantInfo";

// section 2
import { schema as deductibleSchema } from "../Section2/Deductible";
import { schema as premiumPaymmentSchema } from "../Section2/PremiumPayment";

// section 3
import { schema as affirmationSchema } from "../Section3/Affirmation";

// Define the type for Schema
type Schema = {
	values: Record<string, any>;
	validation: any;
};

// Create a schema map for each section
export const schemas: Record<string, Schema> = {
	applicantInfoSchema,
	deductibleSchema,
	premiumPaymmentSchema,
	affirmationSchema,
};

export const newbornFormValues = {
	...applicantInfoSchema.values,
	...deductibleSchema.values,
	...premiumPaymmentSchema.values,
	...affirmationSchema.values,
};

export const newbornFormSchema = [
	introSchema.validation,
	applicantInfoSchema.validation,
	deductibleSchema.validation,
	premiumPaymmentSchema.validation,
	affirmationSchema.validation,
];
