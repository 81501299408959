import React, { createContext, useContext, useState } from "react";

// Create context
const FormContext = createContext<any>(null);

// Create a provider component
export const FormProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [verificationMethod, setContextVerificationMethod] = useState<
    "phone" | "email"
  >("phone");
  const [contactInfo, setContextContactInfo] = useState<string>("");
  const [formId, setContextFormId] = useState<string>("");

  return (
    <FormContext.Provider
      value={{
        verificationMethod,
        setContextVerificationMethod,
        contactInfo,
        setContextContactInfo,
        formId,
        setContextFormId,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

// Create a hook for easier access to the context
export const useFormContext = () => {
  return useContext(FormContext);
};
