import Dropdown from "../../../components/FormFields/Dropdown";
import CheckBox from "../../../components/FormFields/CheckBox";
import TextField from "../../../components/FormFields/Textfield";
import Radio from "../../../components/FormFields/Radio";
import DateField from "../../../components/FormFields/DateField";
import Signature from "../../../components/FormFields/Signature";
import FileUpload from "../../../components/FormFields/FileUpload";
import Modal from "../../../components/Modal/Modal";
import Button, { ColorStyle } from "../../../components/Button/Button";
import NoticeBanner from "../../../components/NoticeBanner/NoticeBanner";
import * as Yup from "yup";
import FormGrid from "../../../components/FormGrid/FormGrid";
import { useFormikContext } from "formik";
import { useEffect } from "react";
import { getNestedValue } from "../../../utils/getNestedValue";
import { useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import styled, { css, useTheme } from "styled-components";
import {
	countyOptions,
	householdSizeOptions,
	povertyGuidelineOptions,
} from "../FormSchema/formInitialValues";
import StyledLegend, {
	BlueLegendHeading,
} from "../../../components/Legend/Legend";
import { useLocation } from "react-router-dom";
import { DeductibleAmounts } from "../../HealthCareCoverageForm/FormSchema/formInitialValues";
import StyledList from "../../../components/Lists/Lists";

const requiredWarning = "This field is required.";

export const schema = {
	values: {
		totalAnnualIncome: "",
	},
	validation: Yup.object().shape({
		totalAnnualIncome: Yup.string().required(requiredWarning),
	}),
};

const GrossIncome = () => {
	const { values, setFieldValue } = useFormikContext<any>();
	const theme = useTheme();

	return (
		<>
			<p>
				You do not need to include income information or verification for any
				member of your household whose income is from Supplementary Security
				Income (SSI) and/or Temporary Assistance for Needy Families (TANF) only.
			</p>

			<FormGrid className="flex-col">
				<StyledLegend className={`!mb-0 !max-w-[800px]`}>
					<BlueLegendHeading>
						<span className="underline">Total Household Annual Income</span>
					</BlueLegendHeading>
					<p className="font-normal mb-[1rem]">
						List total annual income amount for adults in your household except
						as excluded above (from Federal Tax Form 1040)
					</p>

					<TextField
						className="max-w-[300px]"
						type="currency"
						name="totalAnnualIncome"
						label="Enter Amount"
						required
					/>
				</StyledLegend>
			</FormGrid>
		</>
	);
};

export default GrossIncome;
