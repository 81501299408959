import Dropdown from "../../../components/FormFields/Dropdown";
import CheckBox from "../../../components/FormFields/CheckBox";
import TextField from "../../../components/FormFields/Textfield";
import Radio from "../../../components/FormFields/Radio";
import DateField from "../../../components/FormFields/DateField";
import Signature from "../../../components/FormFields/Signature";
import FileUpload from "../../../components/FormFields/FileUpload";
import Modal from "../../../components/Modal/Modal";
import Button, { ColorStyle } from "../../../components/Button/Button";
import NoticeBanner from "../../../components/NoticeBanner/NoticeBanner";
import * as Yup from "yup";
import FormGrid from "../../../components/FormGrid/FormGrid";
import { useFormikContext } from "formik";
import { AgentTypeOptions, USStates } from "../FormSchema/formInitialValues";
import { useEffect } from "react";
import { getNestedValue } from "../../../utils/getNestedValue";
import { useState } from "react";
import { FaArrowLeft } from "react-icons/fa";

const requiredWarning = "This field is required.";

export const schema = {
	values: {
		insuranceAgent: {
			type: "",
			name: "",
			taxIdOrLicense: "",
			address: "",
			city: "",
			state: "",
			zipCode: "",
			email: "",
			phone: "",
			fax: "",
		},
	},
	validation: Yup.object().shape({
		insuranceAgent: Yup.object().shape({
			type: Yup.string().required(requiredWarning),
			name: Yup.string().required(requiredWarning),
			taxIdOrLicense: Yup.string().required(requiredWarning),
			address: Yup.string().required(requiredWarning),
			city: Yup.string().required(requiredWarning),
			state: Yup.string().required(requiredWarning),
			zipCode: Yup.string().required(requiredWarning),
			email: Yup.string().email("Invalid email").required(requiredWarning),
			phone: Yup.string().required(requiredWarning),
			fax: Yup.string().required(requiredWarning),
		}),
	}),
};

const AgentInformation = () => {
	const { values } = useFormikContext<any>();
	const agentType = values["insuranceAgent"]["type"];

	return (
		<>
			<h2>
				Agent, broker, state agency, foundation, or third party sponsor of
				applicant, complete the information below.
			</h2>
			<Dropdown
				className="!max-w-[400px]"
				name="insuranceAgent.type"
				label="Select Type"
				options={AgentTypeOptions}
				required
			/>

			{agentType && (
				<>
					<h2>
						{AgentTypeOptions.find((type) => type.label === agentType)?.label}
					</h2>

					<FormGrid>
						<TextField
							className="!basis-[500px]"
							type="text"
							name="insuranceAgent.name"
							label="Name"
							required
							autoComplete="off"
						/>
						<TextField
							className="!basis-[500px]"
							type="text"
							name="insuranceAgent.taxIdOrLicense"
							label="Tax ID or NPN"
							required
							autoComplete="off"
						/>
						<TextField
							className="!basis-[500px]"
							type="text"
							name="insuranceAgent.address"
							label="Address"
							required
							autoComplete="off"
						/>
						<TextField
							className="!max-w-[250px]"
							type="text"
							name="insuranceAgent.city"
							label="City"
							required
							autoComplete="off"
						/>
						<Dropdown
							className="!max-w-[250px]"
							name="insuranceAgent.state"
							label="State"
							options={USStates}
							required
						/>
						<TextField
							className="!max-w-[250px]"
							type="text"
							name="insuranceAgent.zipCode"
							label="Zip Code"
							required
							maxLength={5}
							autoComplete="off"
						/>
						<TextField
							type="text"
							name="insuranceAgent.email"
							label="Email Address"
							required
							autoComplete="off"
						/>
						<TextField
							className="!max-w-[250px]"
							type="text"
							name="insuranceAgent.phone"
							label="Phone"
							required
							autoComplete="off"
						/>
						<TextField
							className="!max-w-[250px]"
							type="text"
							name="insuranceAgent.fax"
							label="Fax"
							required
							autoComplete="off"
						/>
					</FormGrid>
				</>
			)}
		</>
	);
};

export default AgentInformation;
