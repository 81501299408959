// extracts nested formik value paths
export const getNestedValue = (obj: any, path: string) => {
	if (!obj) return null;

	try {
		if (path.includes(".")) {
			return path.split(".").reduce((acc, part) => {
				if (acc === undefined || acc === null) {
					return undefined;
				}
				return acc[part];
			}, obj);
		} else if (obj[path]) {
			return obj[path];
		} else return null;
	} catch (error) {
		return undefined;
	}
};
