export const blobToPdf = (blob: Blob, fileName: string) => {
	// Create a URL for the Blob object
	const url = URL.createObjectURL(blob);

	// Create an anchor element and trigger the download
	const link = document.createElement("a");
	link.href = url;
	link.download = fileName; // The name of the file to be downloaded

	// Append the anchor to the document body (required for some browsers)
	document.body.appendChild(link);

	// Programmatically click the anchor to trigger the download
	link.click();

	// Clean up by revoking the object URL and removing the anchor from the DOM
	document.body.removeChild(link);
	URL.revokeObjectURL(url);
};
