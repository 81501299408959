import { DefaultTheme } from "styled-components";

export const colors = {
	//  default colors
	colorPrimary: "#101573",
	colorSecondary: "#CF2C4F",

	// backgrounds
	colorBackgroundLightLight: "#FFFFFF",
	colorBackgroundLightBlue: "#D0DEEC",
	colorBackgroundFocus: "#EDF2F8",
	colorBackgroundBlue: "#92B8DD",

	// fields
	colorField: "#D7E2EF",
	colorFieldSelected: "#BED3E8",

	// copy
	colorCopyDarkDark: "#000000",
	colorCopyDark: "#595959",
	colorCopyLight: "#FFFFFF",
	colorCopyLightLight: "#FFFFFF",
	colorCopyBlue: "#102a5d",
};

export const screens = {
	xxs: "360px",
	xs: "480px",
	sm: "576px",
	md: "768px",
	lg: "992px",
	xl: "1200px",
	xxl: "1400px",
};

export const fontStyles = {
	fontCopy: `"Chivo", sans-serif`,
	fontSrc:
		"https://fonts.googleapis.com/css2?family=Chivo:ital,wght@0,100..900;1,100..900&display=swap",
	fontDefaultWeight: "400",
	fontMediumWeight: "600",
	fontSemiBold: "700",
};

export const fontSizes = {
	h1Size: "32px",
	h2Size: "24px",
	h3Size: "22px",
	h4Size: "18px",
	h5Size: "18px",
	h6Size: "18px",
	pSize: "18px",
	aSize: "18px",
	spanSize: "16px",
	xSmallSize: "16px",
	xxSmallSize: "14px",
	xxxSmallSize: "12px",
};

export const borders = {
	fields: {
		thickness: "2px",
		radius: "8px",
	},
};
const theme: DefaultTheme = {
	...colors,
	...screens,
	...fontStyles,
	...fontSizes,
};

export default theme;
