import styled from "styled-components";

const StyledList = styled.div`
	ul,
	ol {
		padding: 0;
		line-height: 26px;
		list-style-position: outside;
		margin-left: 35px;
	}

	ol {
		&.list-alpha {
			list-style-type: lower-alpha;
		}
	}

	li {
		margin-bottom: 6px;
	}
`;

export default StyledList;
