const calculateAge = (dateString: string) => {
	const birthDate = new Date(dateString);
	const today = new Date();

	// Calculate the difference in years
	let age = today.getFullYear() - birthDate.getFullYear();
	const monthDifference = today.getMonth() - birthDate.getMonth();

	// Adjust age if the birthday hasn't occurred yet this year
	if (
		monthDifference < 0 ||
		(monthDifference === 0 && today.getDate() < birthDate.getDate())
	) {
		age--;
	}

	return age;
};

export default calculateAge;
