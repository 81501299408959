import Intro from "../SectionIntro/Intro";
import ApplicantInfo from "../Section1/ApplicantInfo";
import CoverageDetails from "../Section5/CoverageDetails";
import MedicalConditions from "../Section3/MedicalConditions";
import InsuranceAgent from "../Section4/InsuranceAgent";
import AgentInformation from "../Section4/AgentInformation";
import PremiumPayment from "../Section6/PremiumPayment";
import Affirmation from "../Section7/Affirmation";
import Submission from "../Submission/Submission";
import QualifyingConditions from "../Section2/QualifyingConditions";
import QualifyingConditions2 from "../Section2/QualifyingConditions2";
import MedicalInformation from "../Section3/MedicalInformation";
import Physician from "../Section3/Physician";

export const renderFormStep = (step: number) => {
	switch (step) {
		case 0:
			return <Intro />;
		case 1:
			return <ApplicantInfo />;
		case 2:
			return <QualifyingConditions />;
		case 3:
			return <QualifyingConditions2 />;
		case 4:
			return <MedicalInformation />;
		case 5:
			return <Physician />;
		case 6:
			return <MedicalConditions />;
		case 7:
			return <InsuranceAgent />;
		case 8:
			return <AgentInformation />;
		case 9:
			return <CoverageDetails />;
		case 10:
			return <PremiumPayment />;
		case 11:
			return <Affirmation />;
		case 12:
			return <Submission />;
		default:
			return <div>Not Found</div>;
	}
};

export const steps: Step[] = [
	{
		heading: "Application for Coverage Medicare Carve-Out",
	},
	{
		heading: "Applicant Information",
		subHeading:
			"Please fill in your information below. You must be a New Mexico resident and provide proof of residency documentation to be eligible for healthcare coverage from NMMIP.",
	},
	{
		heading: "Qualifying Conditions",
	},
	{
		heading: "Qualifying Conditions",
		subHeading:
			"To determine if you meet the eligibility criteria select YES or NO for every question below.",
	},
	{
		heading: "Medical Information",
	},
	{
		heading: "Medical Information",
		subHeading:
			"If you have a Primary Care Physician (PCP) and/or Specialist, complete the information below.",
	},
	{
		heading: "Medical Conditions",
		subHeading:
			"Check any of the following medical conditions that you have. You may be eligible for additional services.",
	},
	{
		heading: "Agent, State Agency/Foundation, Third Party Sponsor",
		subHeading:
			"Insurance agents in your community can assist you in completing this application at no cost to you. Use the “Find a Broker” button below for a list of brokers.",
	},
	{
		heading: "Agent, State Agency/Foundation, Third Party Sponsor",
	},
	{
		heading: "Coverage Start Date & Deductible",
	},
	{
		heading: "Premium Payment Information",
	},
	{
		heading: "Affirmation, Understanding & Disclosure Authorization",
	},
	{
		heading: "Thank you for Submitting your Application for Medicare Carve-Out",
	},
];

interface Step {
	heading: string;
	subHeading?: string;
}
