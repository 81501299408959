import Dropdown from "../../../components/FormFields/Dropdown";
import CheckBox from "../../../components/FormFields/CheckBox";
import TextField from "../../../components/FormFields/Textfield";
import Radio from "../../../components/FormFields/Radio";
import DateField from "../../../components/FormFields/DateField";
import Signature from "../../../components/FormFields/Signature";
import FileUpload from "../../../components/FormFields/FileUpload";
import Modal from "../../../components/Modal/Modal";
import Button, { ColorStyle } from "../../../components/Button/Button";
import NoticeBanner from "../../../components/NoticeBanner/NoticeBanner";
import * as Yup from "yup";
import FormGrid from "../../../components/FormGrid/FormGrid";
import { useFormikContext } from "formik";
import { getNestedValue } from "../../../utils/getNestedValue";
import { useEffect, useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import { normalizeDate } from "../../../utils/normalizeDate";

const requiredWarning = "This field is required.";

function isDateWithin12Months(dateToCheck: string) {
	// Get today's date
	const today = normalizeDate(new Date());

	// Create a date object representing 12 months before today
	const pastDate = new Date(today);
	pastDate.setMonth(today.getMonth() - 12);
	pastDate.setHours(0, 0, 0, 0);

	// Convert dateToCheck to a Date object if it's not already
	const checkDate = normalizeDate(new Date(dateToCheck));

	// Check if dateToCheck is within the past 12 months
	return checkDate <= today && checkDate >= pastDate;
}

export const schema = {
	values: {
		hasIndividualHealthCoverage: "",
		droppedNMMIPCoverage: "",
		droppedCoverageEndDate: "",
		terminatedInsurance: "",
	},
	validation: Yup.object().shape({
		hasIndividualHealthCoverage: Yup.string().required(requiredWarning),
		droppedNMMIPCoverage: Yup.string().required(requiredWarning),
		terminatedInsurance: Yup.string().required(requiredWarning),
		droppedCoverageEndDate: Yup.string()
			.nullable()
			.test({
				name: "droppedCoverageEndDateRequired",
				message: requiredWarning,
				test(value, ctx) {
					const { droppedNMMIPCoverage } = ctx.parent;
					return droppedNMMIPCoverage === "Yes" ? (value ? true : false) : true;
				},
			})
			.test({
				name: "droppedCoverageEndDate12Months",
				message: "Coverage end date should be within last 12 months",
				test(value, ctx) {
					const { droppedNMMIPCoverage } = ctx.parent;
					return droppedNMMIPCoverage === "Yes"
						? value && isDateWithin12Months(value)
							? true
							: false
						: true;
				},
			}),
	}),
};

const GeneralExclusions2 = () => {
	const { errors, values } = useFormikContext<any>();
	const hasIndividualHealthCoverage = values["hasIndividualHealthCoverage"];
	const droppedNMMIPCoverage = values["droppedNMMIPCoverage"];
	const terminatedInsurance = values["terminatedInsurance"];

	const [isModalOpen, setIsModalOpen] = useState(false);

	useEffect(() => {
		if (hasIndividualHealthCoverage === "Yes") {
			setIsModalOpen(true);
		} else {
			setIsModalOpen(false);
		}
	}, [hasIndividualHealthCoverage]);

	useEffect(() => {
		if (droppedNMMIPCoverage === "Yes") {
			setIsModalOpen(true);
		} else {
			setIsModalOpen(false);
		}
	}, [droppedNMMIPCoverage]);

	useEffect(() => {
		if (terminatedInsurance === "Yes") {
			setIsModalOpen(true);
		} else {
			setIsModalOpen(false);
		}
	}, [terminatedInsurance]);

	return (
		<>
			<FormGrid className="flex-col">
				<Radio
					className="!max-w-[850px]"
					name="hasIndividualHealthCoverage"
					label="I currently have individual comprehensive health coverage. (If you have limited coverage, you may still qualify.)"
					options={[{ label: "Yes" }, { label: "No" }]}
					required
				/>
				<Radio
					className="!max-w-[850px]"
					name="droppedNMMIPCoverage"
					label="I voluntarily dropped NMMIP coverage within the last 12 months."
					options={[{ label: "Yes" }, { label: "No" }]}
					required
				/>
				{droppedNMMIPCoverage === "Yes" && (
					<DateField
						className="h-fit flex-1 min-w-[400px]"
						name="droppedCoverageEndDate"
						label="Select your last coverage date with NMMIP"
						placeholder="Select your last coverage date with NMMIP"
						required
					/>
				)}
				<Radio
					className="!max-w-[850px]"
					name="terminatedInsurance"
					label="My most recent health insurance coverage was terminated due to non-payment of premiums or fraud."
					options={[{ label: "Yes" }, { label: "No" }]}
					required
				/>
			</FormGrid>

			<Modal
				modalLabel="NMMIP Eligibility Criteria"
				open={isModalOpen}
				setOpen={setIsModalOpen}
			>
				<NoticeBanner bannerstyle={ColorStyle.Secondary}>
					Your response may indicate that you are not eligible for coverage.
					However, you can continue to submit an application for review.
				</NoticeBanner>

				<Button
					className="mx-auto"
					type="button"
					onClick={() => setIsModalOpen(false)}
				>
					Continue Application <FaArrowRight />
				</Button>
			</Modal>
		</>
	);
};

export default GeneralExclusions2;
