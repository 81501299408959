function camelCaseToCapitalizedString(camelCaseString: string) {
	// Insert a space before each uppercase letter
	let spacedString = camelCaseString.replace(/([a-z])([A-Z])/g, "$1 $2");

	// Capitalize the first letter of each word
	let capitalizedString = spacedString
		.split(" ")
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		.join(" ");

	return capitalizedString;
}

export const objectToArray = (obj: any) => {
	let result: ErrorType[] = [];

	function recurse(current: any) {
		for (let key in current) {
			if (current.hasOwnProperty(key)) {
				if (typeof current[key] === "object" && current[key] !== null) {
					recurse(current[key]); // Recursive call for nested objects
				} else {
					// Push an object with a single key-value pair
					result.push({ [camelCaseToCapitalizedString(key)]: current[key] });
				}
			}
		}
	}

	recurse(obj); // Start recursion from the root object
	return result;
};

interface ErrorType {
	[name: string]: string;
}
