import Button, { ColorStyle } from "../Button/Button";
import { FaDownload } from "react-icons/fa";

const DownloadPdfButton = (props: { onClick(): void }) => {
	return (
		<Button
			type="button"
			buttonstyle={ColorStyle.Primary}
			onClick={() => props.onClick()}
		>
			Download Application
			<FaDownload />
		</Button>
	);
};

export default DownloadPdfButton;
