import {
	PDFDownloadLink,
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	DocumentProps,
	Link,
	Image,
} from "@react-pdf/renderer";
import { medicareFormValues } from "../FormSchema/formInitialValues";
import PdfPage, {
	displayFormattedDate,
	fieldStyles,
	PdfCheckbox,
	PdfField,
	pdfStyles,
	YesNoCheckbox,
} from "../../../components/PdfDownload/PdfTemplate";
import calculateAge from "../../../utils/calculateAge";
import {
	BankAccountTypes,
	DebitWithdrawlDate,
	MedicalConditionOptions,
	PaymentTypes,
} from "../../HealthCareCoverageForm/FormSchema/formInitialValues";

const MedicarePdf = (props: { formValues: typeof medicareFormValues }) => {
	const { formValues } = props;

	return (
		<Document>
			<PdfPage
				heading="Application for Coverage"
				heading2="Medicare Carve-Out"
				pageLabel="Page 1 of 3"
				revisionLabel="Revision 08.26.24"
			>
				<View style={{ ...pdfStyles.section }}>
					<Text style={{ ...pdfStyles.wFull, ...pdfStyles.mb10 }}>
						To be eligible for the Medicare Carve-Out plan, you must be under
						age 65 and be enrolled in Medicare Parts A{" "}
						<Text style={{ ...pdfStyles.fontBold, ...pdfStyles.textUnderline }}>
							and
						</Text>{" "}
						B due to disability.
					</Text>
					<Text style={{ ...pdfStyles.wFull, ...pdfStyles.mb10 }}>
						NOTE: Every person applying for a New Mexico Medical Insurance Pool
						policy, even if in the same family, must complete a separate
						application.
					</Text>

					<Text>
						For assistance, please contact{" "}
						<Link style={pdfStyles.link} src="tel:+18663061882">
							1-866-306-1882
						</Link>
					</Text>
					<Text>
						or email{" "}
						<Link
							style={pdfStyles.link}
							src="mailto:NMMIP_Eligibility@90degreebenefits.com"
						>
							NMMIP_Eligibility@90degreebenefits.com
						</Link>
						.
					</Text>
				</View>

				<View style={{ ...pdfStyles.section, ...fieldStyles.fieldContainer }}>
					<Text style={{ ...fieldStyles.heading }}>Applicant Information</Text>
					<PdfField label="Last Name" style={{ ...pdfStyles.w50 }}>
						<Text>{formValues.applicant.lastName}</Text>
					</PdfField>
					<PdfField label="First Name" style={{ ...pdfStyles.w40 }}>
						<Text>{formValues.applicant.firstName}</Text>
					</PdfField>
					<PdfField
						label="MI"
						style={{ ...pdfStyles.w10, ...fieldStyles.borderRight }}
					>
						<Text>{formValues.applicant.middleInitial}</Text>
					</PdfField>
					<PdfField label="Age" style={{ ...pdfStyles.w10 }}>
						<Text>
							{formValues.applicant.birthDate &&
								calculateAge(formValues.applicant.birthDate)}
						</Text>
					</PdfField>
					<PdfField
						label="Birth Date (MM/DD/YYYY)"
						style={{ ...pdfStyles.w40 }}
					>
						{displayFormattedDate(formValues.applicant.birthDate)}
					</PdfField>
					<PdfField label="Gender" style={{ ...pdfStyles.w15 }}>
						<View style={{ ...pdfStyles.flexCol }}>
							<PdfCheckbox
								label="Male"
								isChecked={formValues.applicant.gender === "Male"}
							/>
							<PdfCheckbox
								label="Female"
								isChecked={formValues.applicant.gender === "Female"}
							/>
						</View>
					</PdfField>
					<PdfField
						label="Social Security Number (if applicable)"
						style={{ ...pdfStyles.w35, ...fieldStyles.borderRight }}
					>
						<Text>{formValues.applicant.ssn}</Text>
					</PdfField>

					{/* residence address */}
					<PdfField
						label="Residence Address (Physical address required)"
						style={{ ...pdfStyles.w35 }}
					>
						<Text>{formValues.residence.address}</Text>
					</PdfField>
					<PdfField label="City" style={{ ...pdfStyles.w25 }}>
						<Text>{formValues.residence.city}</Text>
					</PdfField>
					<PdfField label="State" style={{ ...pdfStyles.w10 }}>
						<Text>NM</Text>
					</PdfField>
					<PdfField label="Zip" style={{ ...pdfStyles.w15 }}>
						<Text>{formValues.residence.zipCode}</Text>
					</PdfField>
					<PdfField
						label="County"
						style={{ ...pdfStyles.w15, ...fieldStyles.borderRight }}
					>
						<Text>{formValues.residence.county}</Text>
					</PdfField>

					{/* mailing address */}
					<PdfField label="Mailing Address" style={{ ...pdfStyles.w35 }}>
						<Text>{formValues.mailing.address}</Text>
					</PdfField>
					<PdfField label="City" style={{ ...pdfStyles.w25 }}>
						<Text>{formValues.mailing.city}</Text>
					</PdfField>
					<PdfField label="State" style={{ ...pdfStyles.w10 }}>
						<Text>NM</Text>
					</PdfField>
					<PdfField label="Zip" style={{ ...pdfStyles.w15 }}>
						<Text>{formValues.mailing.zipCode}</Text>
					</PdfField>
					<PdfField
						label="County"
						style={{ ...pdfStyles.w15, ...fieldStyles.borderRight }}
					>
						<Text>{formValues.mailing.county}</Text>
					</PdfField>

					{/* contact info */}
					<PdfField
						label="Email Address (optional)"
						style={{ ...pdfStyles.w40 }}
					>
						<Text>{formValues.applicant.email}</Text>
					</PdfField>
					<PdfField label="Home Phone" style={{ ...pdfStyles.w20 }}>
						<Text>{formValues.applicant.homePhone}</Text>
					</PdfField>
					<PdfField label="Work Phone" style={{ ...pdfStyles.w20 }}>
						<Text>{formValues.applicant.workPhone}</Text>
					</PdfField>
					<PdfField
						label="Cell Phone"
						style={{ ...pdfStyles.w20, ...fieldStyles.borderRight }}
					>
						<Text>{formValues.applicant.cellPhone}</Text>
					</PdfField>

					<PdfField label="Preferred Language" style={{ ...pdfStyles.w50 }}>
						<Text>{formValues.applicant.preferredLanguage}</Text>
					</PdfField>
					<PdfField
						label="Preferred Method of Communication"
						style={{ ...pdfStyles.w30 }}
					>
						<View style={{ ...pdfStyles.flexRow, ...pdfStyles.gap10 }}>
							<PdfCheckbox
								label="Phone"
								isChecked={
									formValues.applicant.preferredCommunication === "Phone"
								}
							/>
							<PdfCheckbox
								label="Text"
								isChecked={
									formValues.applicant.preferredCommunication === "Text"
								}
							/>
							<PdfCheckbox
								label="Email"
								isChecked={
									formValues.applicant.preferredCommunication === "Email"
								}
							/>
						</View>
					</PdfField>
					<PdfField
						label="Hearing Impaired"
						style={{ ...pdfStyles.w20, ...fieldStyles.borderRight }}
					>
						<View style={{ ...pdfStyles.flexRow, ...pdfStyles.gap10 }}>
							<PdfCheckbox
								label="Yes"
								isChecked={formValues.applicant.isHearingImpaired === "Yes"}
							/>
							<PdfCheckbox
								label="No"
								isChecked={formValues.applicant.isHearingImpaired === "No"}
							/>
						</View>
					</PdfField>

					<PdfField
						label=""
						style={{
							...pdfStyles.wFull,
							...fieldStyles.borderRight,
							...fieldStyles.borderBottom,
						}}
					>
						<Text style={{ ...pdfStyles.font8 }}>
							Are you a resident of the State of New Mexico? If YES, provide{" "}
							<Text style={{ ...pdfStyles.fontBold }}>proof of residency</Text>{" "}
							(lease agreement, mortgage statement, utility bill, voter
							registration, bank or credit card statement, or another document).
						</Text>

						<View style={{ ...pdfStyles.flexRow, ...pdfStyles.gap10 }}>
							<PdfCheckbox
								label="Yes"
								isChecked={formValues.residence.state === "New Mexico"}
							/>
							<PdfCheckbox
								label="No"
								isChecked={
									formValues.residence.state !== "" &&
									formValues.residence.state !== "New Mexico"
								}
							/>
						</View>
					</PdfField>
				</View>

				<View style={{ ...pdfStyles.section, ...fieldStyles.fieldContainer }}>
					<Text style={{ ...fieldStyles.heading }}>Qualifying Conditions</Text>

					<PdfField
						label=""
						style={{
							...pdfStyles.wFull,
							...fieldStyles.borderRight,
							...fieldStyles.fieldSmall,
							...pdfStyles.bgGray,
						}}
					>
						<Text
							style={{
								...pdfStyles.wFull,
								...pdfStyles.fontBold,
							}}
						>
							Please answer every question:
						</Text>
					</PdfField>
					<YesNoCheckbox
						label="I am under 65 years of age and enrolled in Medicare due to a disability."
						value={formValues.isUnder65AndDisabled}
					/>

					<PdfField
						label=""
						style={{
							...pdfStyles.wFull,
							...pdfStyles.p0,
							...fieldStyles.borderRight,
						}}
					>
						<View
							style={{
								...pdfStyles.flexRow,
							}}
						>
							<View
								style={{
									...fieldStyles.checkBoxContainer,
								}}
							>
								<PdfCheckbox
									label="Yes"
									isChecked={formValues.haveMedicarePartAandB === "Yes"}
								/>
								<PdfCheckbox
									label="No"
									isChecked={formValues.haveMedicarePartAandB === "No"}
								/>
							</View>
							<View
								style={{
									...pdfStyles.flexCol,
									...pdfStyles.flex1,
									...pdfStyles.p3,
								}}
							>
								<Text
									style={{
										...pdfStyles.mb5,
									}}
								>
									I have Medicare{" "}
									<Text style={{ ...pdfStyles.fontBold }}>
										(copy of your award letter or Medicare Card is required.)
									</Text>
								</Text>

								<Text>
									Part A Effective Date:{" "}
									{formValues.partAEffectiveDate ? (
										<Text style={{ ...pdfStyles.textUnderline }}>
											{displayFormattedDate(formValues.partAEffectiveDate)}
										</Text>
									) : (
										"______________________"
									)}
								</Text>

								<Text>
									Part B Effective Date:{" "}
									{formValues.partBEffectiveDate ? (
										<Text style={{ ...pdfStyles.textUnderline }}>
											{displayFormattedDate(formValues.partBEffectiveDate)}
										</Text>
									) : (
										"______________________"
									)}
								</Text>
							</View>
						</View>
					</PdfField>

					<PdfField
						label=""
						style={{
							...pdfStyles.wFull,
							...pdfStyles.p0,
							...fieldStyles.borderRight,
						}}
					>
						<View
							style={{
								...pdfStyles.flexRow,
							}}
						>
							<View
								style={{
									...fieldStyles.checkBoxContainer,
								}}
							>
								<PdfCheckbox
									label="Yes"
									isChecked={
										formValues.haveInsuranceOtherThanMedicaid === "Yes"
									}
								/>
								<PdfCheckbox
									label="No"
									isChecked={formValues.haveInsuranceOtherThanMedicaid === "No"}
								/>
							</View>

							<View
								style={{
									...pdfStyles.flexCol,
									...pdfStyles.flex1,
									...pdfStyles.p3,
								}}
							>
								<Text
									style={{
										...pdfStyles.mb5,
									}}
								>
									I have other insurance other than Medicare.
								</Text>

								<Text>
									If yes, with what insurance company?{" "}
									{formValues.insuranceCompanyDetails.insuranceCompanyName ? (
										<Text style={{ ...pdfStyles.textUnderline }}>
											{formValues.insuranceCompanyDetails.insuranceCompanyName}
										</Text>
									) : (
										"______________________"
									)}
								</Text>

								<Text>
									When does coverage end?{" "}
									{formValues.insuranceCompanyDetails.coverageEndDate ? (
										<Text style={{ ...pdfStyles.textUnderline }}>
											{displayFormattedDate(
												formValues.insuranceCompanyDetails.coverageEndDate
											)}
										</Text>
									) : (
										"______________________"
									)}
								</Text>

								<Text>
									Why is coverage ending?{" "}
									{formValues.insuranceCompanyDetails.whyIsCoverageEnding ? (
										<Text style={{ ...pdfStyles.textUnderline }}>
											{formValues.insuranceCompanyDetails.whyIsCoverageEnding}
										</Text>
									) : (
										"______________________"
									)}
								</Text>
							</View>
						</View>
					</PdfField>

					<PdfField
						label=""
						style={{
							...pdfStyles.wFull,
							...pdfStyles.p0,
							...fieldStyles.borderRight,
							...fieldStyles.borderBottom,
						}}
					>
						<View
							style={{
								...pdfStyles.flexRow,
							}}
						>
							<View
								style={{
									...fieldStyles.checkBoxContainer,
								}}
							>
								<PdfCheckbox
									label="Yes"
									isChecked={
										formValues.haveInsuranceOtherThanMedicaid === "Yes"
									}
								/>
								<PdfCheckbox
									label="No"
									isChecked={formValues.haveInsuranceOtherThanMedicaid === "No"}
								/>
							</View>
							<View
								style={{
									...pdfStyles.flexCol,
									...pdfStyles.flex1,
									...pdfStyles.p3,
								}}
							>
								<Text>
									I have been covered by NMMIP in the past. Dates of coverage:
								</Text>

								<Text>
									from:{" "}
									{formValues.nmmipCoverageDetails.priorCoverageStartDate ? (
										<Text style={{ ...pdfStyles.textUnderline }}>
											{displayFormattedDate(
												formValues.nmmipCoverageDetails.priorCoverageStartDate
											)}
										</Text>
									) : (
										"__________"
									)}{" "}
									to{" "}
									{formValues.nmmipCoverageDetails.priorCoverageEndDate ? (
										<Text style={{ ...pdfStyles.textUnderline }}>
											{displayFormattedDate(
												formValues.nmmipCoverageDetails.priorCoverageEndDate
											)}
										</Text>
									) : (
										"__________"
									)}
								</Text>

								<Text>
									Reason for Termination:{" "}
									{formValues.nmmipCoverageDetails.reasonForTermination ? (
										<Text style={{ ...pdfStyles.textUnderline }}>
											{formValues.nmmipCoverageDetails.reasonForTermination}
										</Text>
									) : (
										"______________________"
									)}
								</Text>
							</View>
						</View>
					</PdfField>
				</View>
			</PdfPage>

			<PdfPage
				heading="Application for Coverage"
				heading2="Medicare Carve-Out"
				pageLabel="Page 2 of 3"
				revisionLabel="Revision 08.26.24"
			>
				<View style={{ ...pdfStyles.section, ...fieldStyles.fieldContainer }}>
					<Text style={{ ...fieldStyles.heading }}>Medical Information</Text>
					<YesNoCheckbox
						label="Do you currently use, or have you used tobacco in any form within the past 12 months?"
						value={formValues.usedTabacco}
					/>

					<YesNoCheckbox
						label={
							"Are you currently inpatient at a hospital facility? Name of Hospital: " +
							(formValues.inpatientHospital ? formValues.inpatientHospital : "")
						}
						value={formValues.inpatient}
					/>

					<PdfField
						label=""
						style={{
							...pdfStyles.wFull,
							...fieldStyles.borderRight,
							...fieldStyles.fieldSmall,
						}}
					>
						<Text>
							If you have a Primary Care Physician (PCP) and/or Specialist,
							complete the information below.
						</Text>
					</PdfField>

					<PdfField label="PCP Name" style={{ ...pdfStyles.w50 }}>
						<Text>{formValues.pcpName}</Text>
					</PdfField>
					<PdfField
						label="PCP Phone Number (include area code)e"
						style={{ ...pdfStyles.w50, ...fieldStyles.borderRight }}
					>
						<Text>{formValues.pcpPhone}</Text>
					</PdfField>
					<PdfField label="Specialist Name" style={{ ...pdfStyles.w50 }}>
						<Text>{formValues.specialistName}</Text>
					</PdfField>
					<PdfField
						label="Specialist Phone Number (include area code)"
						style={{ ...pdfStyles.w50, ...fieldStyles.borderRight }}
					>
						<Text>{formValues.specialistPhone}</Text>
					</PdfField>
					<PdfField
						label="Check any of the following medical conditions that you have. You
							may be eligible for additional services."
						style={{
							...pdfStyles.wFull,
							...fieldStyles.borderRight,
							minHeight: 85,
						}}
					>
						<View
							style={{
								...pdfStyles.flexRow,
								...pdfStyles.gap10,
								...pdfStyles.flex1,
								...pdfStyles.flexWrap,
								...pdfStyles.mt5,
							}}
						>
							{MedicalConditionOptions.map((condition) => (
								<PdfCheckbox
									label={condition.label}
									isChecked={(formValues.medicalConditions as any[]).includes(
										condition.label
									)}
								/>
							))}
						</View>
					</PdfField>
					<PdfField
						label="If your medical condition is not listed, please provide:"
						style={{
							...pdfStyles.wFull,
							...fieldStyles.borderRight,
						}}
					>
						<Text>{formValues.otherCondition}</Text>
					</PdfField>

					<PdfField
						label="How many medications do you take?"
						style={{
							...pdfStyles.wFull,
							...fieldStyles.borderRight,
							...fieldStyles.borderBottom,
						}}
					>
						<Text>{formValues.medicationQuantity}</Text>
					</PdfField>
				</View>

				<View style={{ ...pdfStyles.section, ...fieldStyles.fieldContainer }}>
					<Text style={{ ...fieldStyles.heading }}>
						Agent, State Agency/Foundation, Third Party Sponsor
					</Text>

					<Text>
						Insurance agents in your community can assist you in completing this
						application at no cost to you.
					</Text>
					<Text style={{ ...pdfStyles.mb10 }}>
						Visit{" "}
						<Link style={pdfStyles.link} src="https://www.nmmip.org">
							www.nmmip.org
						</Link>{" "}
						for a list of brokers.
					</Text>
					<Text
						style={{
							...pdfStyles.mb10,
						}}
					>
						Agent, broker, state agency, foundation, or third party sponsor of
						applicant, complete all boxes below.
					</Text>

					<PdfField
						label=""
						style={{
							...pdfStyles.w25,
							...pdfStyles.flexRow,
						}}
					>
						<View
							style={{
								...pdfStyles.flexCol,
							}}
						>
							<PdfCheckbox
								label="Agent/Broker"
								isChecked={formValues.insuranceAgent.type === "Agent/Broker"}
							/>
							<PdfCheckbox
								label="State Agency/Foundation"
								isChecked={
									formValues.insuranceAgent.type === "State Agency/Foundation"
								}
							/>
							<PdfCheckbox
								label="Third Party Sponsor"
								isChecked={
									formValues.insuranceAgent.type === "Third Party Sponsor"
								}
							/>
						</View>
					</PdfField>

					<PdfField
						label="Name"
						style={{
							...pdfStyles.w50,
						}}
					>
						<Text>{formValues.insuranceAgent.name}</Text>
					</PdfField>
					<PdfField
						label=""
						style={{
							...pdfStyles.w25,
							...fieldStyles.borderRight,
						}}
					>
						<Text
							style={{
								...fieldStyles.label,
							}}
						>
							Tax ID Number/
						</Text>
						<Text
							style={{
								...fieldStyles.label,
							}}
						>
							NM License Number
						</Text>
						<Text>{formValues.insuranceAgent.taxIdOrLicense}</Text>
					</PdfField>

					<PdfField label="Address" style={{ ...pdfStyles.w50 }}>
						<Text>{formValues.insuranceAgent.address}</Text>
					</PdfField>
					<PdfField label="City" style={{ ...pdfStyles.w25 }}>
						<Text>{formValues.insuranceAgent.address}</Text>
					</PdfField>
					<PdfField label="State" style={{ ...pdfStyles.w125 }}>
						<Text>{formValues.insuranceAgent.state}</Text>
					</PdfField>
					<PdfField
						label="Zip"
						style={{ ...pdfStyles.w125, ...fieldStyles.borderRight }}
					>
						<Text>{formValues.insuranceAgent.zipCode}</Text>
					</PdfField>
					<PdfField
						label="Email"
						style={{ ...pdfStyles.w50, ...fieldStyles.borderBottom }}
					>
						<Text>{formValues.insuranceAgent.email}</Text>
					</PdfField>
					<PdfField
						label="Phone"
						style={{ ...pdfStyles.w25, ...fieldStyles.borderBottom }}
					>
						<Text>{formValues.insuranceAgent.phone}</Text>
					</PdfField>
					<PdfField
						label="Fax"
						style={{
							...pdfStyles.w25,
							...fieldStyles.borderBottom,
							...fieldStyles.borderRight,
						}}
					>
						<Text>{formValues.insuranceAgent.fax}</Text>
					</PdfField>
				</View>
			</PdfPage>

			<PdfPage
				heading="Application for Coverage"
				heading2="Medicare Carve-Out"
				pageLabel="Page 3 of 3"
				revisionLabel="Revision 08.26.24"
			>
				<View style={{ ...pdfStyles.section, ...fieldStyles.fieldContainer }}>
					<Text style={{ ...fieldStyles.heading }}>
						Premium Payment Information
					</Text>

					<PdfField
						label=""
						style={{
							...pdfStyles.wFull,
							...fieldStyles.borderRight,
							...fieldStyles.borderBottom,
							minHeight: 100,
						}}
					>
						<View
							style={{
								...pdfStyles.flexCol,
								...pdfStyles.gap10,
								...pdfStyles.flex1,
							}}
						>
							<Text>
								Select the method of payment for your initial premium (must be
								included for coverage consideration).
							</Text>
							<View
								style={{
									...pdfStyles.flexRow,
									...pdfStyles.flex1,
									...pdfStyles.fontBold,
									...pdfStyles.wFull,
									alignItems: "flex-end",
								}}
							>
								<Text>Amount $</Text>

								<View
									style={{
										...pdfStyles.w10,
										...pdfStyles.underline,
										...pdfStyles.ml3,
									}}
								>
									<Text>{formValues.paymentAmount}</Text>
								</View>
							</View>
							<View style={{ ...pdfStyles.flexRow, ...pdfStyles.gap10 }}>
								{PaymentTypes.map((type) => (
									<PdfCheckbox
										label={type.label}
										isChecked={formValues.paymentType === type.label}
									/>
								))}
							</View>
							<Text>
								For one-time or monthly ACH, complete and attach Agreement for
								Preauthorized Payments (ACH Form). If paying first premium by
								check or money order, you must MAIL the application and all
								attachments WITH the payment.
							</Text>
						</View>
					</PdfField>
				</View>
				<View style={{ ...pdfStyles.section, ...fieldStyles.fieldContainer }}>
					<Text
						style={{
							...pdfStyles.wFull,
						}}
					>
						I certify that the foregoing statements are true and accurate to the
						best of my knowledge. I understand that no coverage will be
						effective until the full initial premium is paid and this
						application has been approved by the NMMIP Administrator. I
						understand that if I obtain or become eligible for health coverage,
						I will notify the NMMIP Administrator of the other coverage.
					</Text>
				</View>
				<View style={{ ...pdfStyles.section, ...fieldStyles.fieldContainer }}>
					<PdfField
						label=""
						style={{
							...pdfStyles.w85,
							minHeight: 50,
							gap: 0,
						}}
					>
						<Text
							style={{
								...pdfStyles.fontBold,
								...pdfStyles.font10,
							}}
						>
							Signature of Applicant
						</Text>
						<Text
							style={{
								...pdfStyles.font10,
							}}
						>
							Or parent/legal guardian/personal representative if applicant is
							under 18 years of age or legally incompetent.
						</Text>
						{formValues.affirmation.applicantSignature && (
							<Image
								style={fieldStyles.signature}
								src={formValues.affirmation.applicantSignature}
							/>
						)}
					</PdfField>

					<PdfField
						label=""
						style={{
							...pdfStyles.w15,
							...fieldStyles.borderRight,
							minHeight: 70,
						}}
					>
						<Text
							style={{
								...pdfStyles.fontBold,
								...pdfStyles.font10,
							}}
						>
							Today's Date
						</Text>
						<Text>
							{displayFormattedDate(formValues.affirmation.todaysDate)}
						</Text>
					</PdfField>

					<PdfField
						label="PRINTED NAME of parent/legal guardian/personal representative"
						style={{
							...pdfStyles.w50,
							...fieldStyles.borderBottom,
							minHeight: 50,
							...pdfStyles.mb10,
						}}
					>
						<Text>{formValues.affirmation.legalGuardian}</Text>
					</PdfField>

					<PdfField
						label=""
						style={{
							...pdfStyles.w50,
							...fieldStyles.borderBottom,
							...fieldStyles.borderRight,
							minHeight: 50,
							...pdfStyles.mb10,
						}}
					>
						<Text
							style={{
								...pdfStyles.font8,
							}}
						>
							Relationship to Applicant
						</Text>
						<Text
							style={{
								...pdfStyles.font8,
							}}
						>
							Attach legal document if other than Parent.
						</Text>
						<Text>{formValues.affirmation.legalGuardianRelationship}</Text>
					</PdfField>

					<Text style={{ ...pdfStyles.fontBold, ...pdfStyles.textCenter }}>
						If paying first premium by check or money order, you must MAIL the
						application and all attachments WITH the payment.
					</Text>

					<View style={{ ...pdfStyles.contactInfo }}>
						<Text>New Mexico Medical Insurance Pool (NMMIP)</Text>
						<View style={{ ...pdfStyles.flexRow, ...pdfStyles.gap5 }}>
							<Text style={{ ...pdfStyles.fontBold }}>Mail:</Text>
							<Text>P.O. Box 780548, San Antonio, Tx 78278</Text>
						</View>

						<View style={{ ...pdfStyles.flexRow, ...pdfStyles.gap5 }}>
							<Text style={{ ...pdfStyles.fontBold }}>Email:</Text>
							<Link
								style={pdfStyles.link}
								src="mailto:NMMIP_Eligibility@90degreebenefits.com"
							>
								NMMIP_Eligibility@90degreebenefits.com
							</Link>
						</View>

						<View style={{ ...pdfStyles.flexRow, ...pdfStyles.gap5 }}>
							<Text style={{ ...pdfStyles.fontBold }}>Fax:</Text>{" "}
							<Text>210-239-8449</Text>
						</View>
					</View>
				</View>
			</PdfPage>

			{formValues["paymentType"] === PaymentTypes[1].label ||
			formValues["paymentType"] === PaymentTypes[2].label ? (
				<PdfPage
					heading="Agreement for Preauthorized"
					heading2="Payments"
					pageLabel="Page 1 of 1"
					revisionLabel="Revision 08.26.24"
				>
					<View style={{ ...pdfStyles.section }}>
						<Text style={{ ...pdfStyles.wFull }}>
							I hereby authorize the New Mexico Medical Insurance Pool (NMMIP)
							to initiate debit entries from my account and Depository
							designated below. Pursuant to my election, debits will be drawn on
							the first or fifteenth of each month unless the date falls on a
							holiday, then it will be drawn the next business day.{" "}
							<Text style={{ ...pdfStyles.fontBold }}>
								Your account will be drafted for the month in which you are due
							</Text>{" "}
							(e.g., the January amount drafted is for your January coverage).
						</Text>
					</View>

					<View style={{ ...pdfStyles.section, ...fieldStyles.fieldContainer }}>
						<PdfField label="Last Name" style={{ ...pdfStyles.w40 }}>
							<Text>{formValues.preAuthorizedPaymentInfo.lastName}</Text>
						</PdfField>
						<PdfField label="First Name" style={{ ...pdfStyles.w30 }}>
							<Text>{formValues.preAuthorizedPaymentInfo.firstName}</Text>
						</PdfField>

						<PdfField
							label="NMMIP Member ID"
							style={{ ...pdfStyles.w30, ...fieldStyles.borderRight }}
						>
							<Text></Text>
						</PdfField>
						<PdfField
							label="I elect to have funds withdrawn from my account on:"
							style={{ ...pdfStyles.w75 }}
						>
							<View style={{ ...pdfStyles.flexRow, ...pdfStyles.gap10 }}>
								<PdfCheckbox
									label={DebitWithdrawlDate[0].label}
									isChecked={
										formValues.preAuthorizedPaymentInfo.withdrawlDate ===
										DebitWithdrawlDate[0].label
									}
								/>
								<PdfCheckbox
									label={DebitWithdrawlDate[1].label}
									isChecked={
										formValues.preAuthorizedPaymentInfo.withdrawlDate ===
										DebitWithdrawlDate[1].label
									}
								/>
							</View>
						</PdfField>
						<PdfField
							label="Type of account:"
							style={{ ...pdfStyles.w25, ...fieldStyles.borderRight }}
						>
							<View style={{ ...pdfStyles.flexRow, ...pdfStyles.gap10 }}>
								<PdfCheckbox
									label={BankAccountTypes[0].label}
									isChecked={
										formValues.preAuthorizedPaymentInfo.accountType ===
										BankAccountTypes[0].label
									}
								/>
								<PdfCheckbox
									label={BankAccountTypes[1].label}
									isChecked={
										formValues.preAuthorizedPaymentInfo.accountType ===
										BankAccountTypes[1].label
									}
								/>
							</View>
						</PdfField>

						<PdfField
							label="Name of Financial Institution/Bank (Depository)"
							style={{ ...pdfStyles.w50 }}
						>
							<Text>{formValues.preAuthorizedPaymentInfo.bank}</Text>
						</PdfField>
						<PdfField label="City" style={{ ...pdfStyles.w20 }}>
							<Text>{formValues.preAuthorizedPaymentInfo.city}</Text>
						</PdfField>
						<PdfField label="State" style={{ ...pdfStyles.w10 }}>
							<Text>{formValues.preAuthorizedPaymentInfo.state}</Text>
						</PdfField>
						<PdfField
							label="Zip"
							style={{
								...pdfStyles.w20,
								...fieldStyles.borderRight,
							}}
						>
							<Text>{formValues.preAuthorizedPaymentInfo.zipCode}</Text>
						</PdfField>
						<PdfField
							label="Routing Number"
							style={{ ...pdfStyles.w50, ...fieldStyles.borderBottom }}
						>
							<Text>
								{formValues.preAuthorizedPaymentInfo.bankRoutingNumber}
							</Text>
						</PdfField>
						<PdfField
							label="Account Number"
							style={{
								...pdfStyles.w50,
								...fieldStyles.borderRight,
								...fieldStyles.borderBottom,
							}}
						>
							<Text>
								{formValues.preAuthorizedPaymentInfo.bankAccountNumber}
							</Text>
						</PdfField>
					</View>

					<View
						style={{
							...pdfStyles.section,
							...pdfStyles.voidedCheck,
						}}
					>
						<Text style={{ ...fieldStyles.fieldHeader }}>
							Attach a Voided Check
						</Text>
						<Text>A deposit ticket will not be accepted</Text>
					</View>

					<View style={{ ...pdfStyles.section }}>
						<Text style={{ ...pdfStyles.wFull }}>
							This authority is to remain in full force and effect until NMMIP
							and Depository have received written notification from me of this
							agreement's termination in such time and in such manner as to
							afford NMMIP and the Depository reasonable opportunity to act upon
							the request.
						</Text>
					</View>

					<View style={{ ...pdfStyles.section, ...fieldStyles.fieldContainer }}>
						<PdfField
							label=""
							style={{
								...pdfStyles.w75,

								minHeight: 50,
								gap: 0,
							}}
						>
							<Text
								style={{
									...pdfStyles.fontBold,
									...pdfStyles.font10,
								}}
							>
								Signature
							</Text>

							{formValues.preAuthorizedPaymentInfo.signature && (
								<Image
									style={fieldStyles.signature}
									src={formValues.preAuthorizedPaymentInfo.signature}
								/>
							)}
						</PdfField>

						<PdfField
							label="Today's Date"
							style={{
								...pdfStyles.w25,
								...fieldStyles.borderRight,

								minHeight: 50,
							}}
						>
							<Text>
								{displayFormattedDate(
									formValues.preAuthorizedPaymentInfo.todaysDate
								)}
							</Text>
						</PdfField>
						<PdfField
							label=""
							style={{
								...pdfStyles.wFull,
								...fieldStyles.borderRight,
								...fieldStyles.borderBottom,
							}}
						>
							<Text
								style={{
									...pdfStyles.wFull,
									...pdfStyles.fontBold,
									...fieldStyles.fieldSmall,
								}}
							>
								Signature must be from a person who has authority to sign on the
								account to be drafted.
							</Text>
						</PdfField>
					</View>

					<View
						style={{
							...pdfStyles.section,
							...pdfStyles.mt10,
							...pdfStyles.mxAuto,
							...pdfStyles.w50,
						}}
					>
						<View style={{ ...pdfStyles.textCenter }}>
							<Text>Submit this form by mail, email, or fax:</Text>
							<Text style={pdfStyles.fontBold}>
								New Mexico Medical Insurance Pool (NMMIP)
							</Text>
							<Text>Mail: P.O. Box 780548, San Antonio, Tx 78278</Text>
							<Text>
								Email:{" "}
								<Link
									style={pdfStyles.link}
									src="mailto:NMMIP_Eligibility@90degreebenefits.com"
								>
									NMMIP_Eligibility@90degreebenefits.com
								</Link>
							</Text>
							<Text>Fax: 210-239-8449</Text>
						</View>
					</View>

					<View
						style={{
							...pdfStyles.section,
							...pdfStyles.mt10,
							...pdfStyles.mxAuto,
						}}
					>
						<Text>
							For questions, please contact{" "}
							<Link style={pdfStyles.link} src="tel:+18663061882">
								1-866-306-1882
							</Link>{" "}
							or email{" "}
							<Link
								style={pdfStyles.link}
								src="mailto:NMMIP_Eligibility@90degreebenefits.com"
							>
								NMMIP_Eligibility@90degreebenefits.com
							</Link>
							.
						</Text>
					</View>
				</PdfPage>
			) : (
				<></>
			)}
		</Document>
	);
};

export default MedicarePdf;
