import * as Yup from "yup";
import Radio from "../../../components/FormFields/Radio";
import FormGrid from "../../../components/FormGrid/FormGrid";
import Button from "../../../components/Button/Button";

export const schema = {
	values: {},
	validation: Yup.object().shape({}),
};

const Intro = () => {
	return (
		<>
			<p>
				To be eligible for the Medicare Carve-Out plan, you must be under age 65
				and be enrolled in Medicare Parts A{" "}
				<span className="underline">and</span> B due to disability.
				<br /> <br />
				Every person applying for a New Mexico Medical Insurance Pool policy,
				even if in the same family, must complete a separate application.
			</p>
			<Button type="button" href={"https://nmmip.org/rate-tables/"}>
				Medicare Carve Out Rate Plans
			</Button>

			<p>
				Information you add to apply online is only stored here temporarily.
				After seven days, if you have not completed and submitted your
				application, you will need to start over. Once submitted, your
				application is sent to 90 Degree Benefits. For assistance, call
				1-866-306-1882 or email{" "}
				<a href="mailto:NMMIP_Eligibility@90degreebenefits.com">
					NMMIP_Eligibility@90degreebenefits.com.
				</a>
			</p>
		</>
	);
};

export default Intro;
