import * as Yup from "yup";
import Radio from "../../../components/FormFields/Radio";
import FormGrid from "../../../components/FormGrid/FormGrid";
import Button from "../../../components/Button/Button";

export const schema = {
	values: {},
	validation: Yup.object().shape({}),
};

const Intro = () => {
	return (
		<>
			<p>
				Newborn coverage for the first 31 days after birth is a benefit of the
				parent's NMMIP policy. Only current NMMIP enrollees are eligible for
				newborn coverage. At day 32 from the date of birth, the newborn's policy
				will be canceled, unless there is a qualifying event which would make
				the newborn eligible for NMMIP.
				<br /> <br />
				<span className="underline">
					First month's premium must be included with the application.
				</span>
			</p>

			<p>
				Information you add to apply online is only stored here temporarily.
				After seven days, if you have not completed and submitted your
				application, you will need to start over. Once submitted, your
				application is sent to 90 Degree Benefits. For assistance, call
				1-866-306-1882 or email{" "}
				<a href="mailto:NMMIP_Eligibility@90degreebenefits.com">
					NMMIP_Eligibility@90degreebenefits.com.
				</a>
			</p>
		</>
	);
};

export default Intro;
