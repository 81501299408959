import Dropdown from "../../../components/FormFields/Dropdown";
import CheckBox from "../../../components/FormFields/CheckBox";
import TextField from "../../../components/FormFields/Textfield";
import Radio from "../../../components/FormFields/Radio";
import DateField from "../../../components/FormFields/DateField";
import Signature from "../../../components/FormFields/Signature";
import FileUpload from "../../../components/FormFields/FileUpload";
import Modal from "../../../components/Modal/Modal";
import Button, { ColorStyle } from "../../../components/Button/Button";
import NoticeBanner from "../../../components/NoticeBanner/NoticeBanner";
import * as Yup from "yup";
import FormGrid from "../../../components/FormGrid/FormGrid";
import { useFormikContext } from "formik";
import { getNestedValue } from "../../../utils/getNestedValue";
import { useEffect, useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import { normalizeDate } from "../../../utils/normalizeDate";

const requiredWarning = "This field is required.";

export const schema = {
	values: {
		pcpName: "",
		pcpPhone: "",
		specialistName: "",
		specialistPhone: "",
	},
	validation: Yup.object().shape({}),
};

const Physician = () => {
	const { errors, values } = useFormikContext<any>();

	return (
		<>
			<h2>Primary Care Physician</h2>
			<FormGrid>
				<TextField type="text" name="pcpName" label="PCP Name" />
				<TextField type="text" name="pcpPhone" label="PCP Phone Number" />
			</FormGrid>
			<h2>Specialist</h2>
			<FormGrid>
				<TextField type="text" name="specialistName" label="Specialist Name" />
				<TextField
					type="text"
					name="specialistPhone"
					label="Specialist Phone Number"
				/>
			</FormGrid>
		</>
	);
};

export default Physician;
