import styled, { css } from "styled-components";
import FormikField from "./Field";
import { FieldType } from "./fieldTypes";

const TextField = (props: TextFieldProps) => {
	const { name, label, required, type } = props;

	return <FormikField {...props} />;
};

export default TextField;

interface TextFieldProps extends FieldType {
	className?: string;
	type: string;
	maxLength?: number;
	autoComplete?: string;
	disabled?: boolean;
	mobileExtraPadding?: boolean;
	readOnly?: boolean;
}
