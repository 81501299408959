import React, { JSXElementConstructor, ReactElement } from "react";
import {
	PDFDownloadLink,
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	DocumentProps,
	Image,
	Font,
} from "@react-pdf/renderer";
import Button, { ColorStyle } from "../Button/Button";
import { FaDownload } from "react-icons/fa";
import styled from "styled-components";
import logo from "../../assets/NMMIP_logo.png";
import square from "../../assets/square-regular.png";
import squareCheck from "../../assets/square-check-solid.png";
import theme from "../../theme";

Font.register({
	family: "Open Sans",
	fonts: [
		{
			src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
			fontWeight: 400,
		},
		{
			src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
			fontWeight: 600,
		},
		{
			src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf",
			fontWeight: 700,
		},
	],
});

Font.register({
	family: "Merriweather",
	fonts: [
		{
			src: "https://cdn.jsdelivr.net/fontsource/fonts/merriweather@latest/latin-700-normal.ttf",
			fontWeight: 700,
		},
	],
});

export const pdfStyles = StyleSheet.create({
	page: {
		flexDirection: "column",
		paddingHorizontal: 30,
		paddingVertical: 10,
		fontSize: 10,
		fontFamily: "Open Sans",
	},
	section: {
		marginVertical: 10,
	},
	header: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		gap: 20,
		fontSize: 20,
		fontFamily: "Merriweather",
		fontWeight: 700,
	},
	subHeader: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		gap: 20,
		fontSize: 10,
		width: "100%",
	},
	footer: {
		position: "absolute",
		bottom: 5,
		left: 0,
		paddingHorizontal: 30,
		width: "100%",
		color: theme.colorPrimary,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
	},
	logo: {
		width: 175,
	},
	heading: {
		maxWidth: "60vw",
		color: theme.colorPrimary,
		fontWeight: 700,
	},
	container: {
		flexDirection: "column",
	},
	contactInfo: {
		flexDirection: "column",
		color: "#505050",
		alignItems: "center",
		marginTop: 10,
		gap: 5,
		width: "100%",
		textAlign: "center",
	},
	voidedCheck: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		gap: 5,
		width: "100%",
		height: 200,
		borderolor: theme.colorCopyDarkDark,
		borderStyle: "solid",
		border: 1,
	},
	flexRow: {
		flexDirection: "row",
		alignItems: "center",
	},
	flexCol: { flexDirection: "column" },
	justifyBetween: {
		justifyContent: "space-between",
	},
	gap5: {
		gap: 5,
	},
	gap10: {
		gap: 10,
	},
	fontSemiBold: {
		fontFamily: "Open Sans",
		fontWeight: 600,
	},
	fontBold: {
		fontFamily: "Open Sans",
		fontWeight: 700,
	},
	textCenter: {
		textAlign: "center",
	},
	textRight: {
		textAlign: "right",
	},
	maxW70: {
		maxWidth: "70%",
	},
	link: {
		color: theme.colorPrimary,
	},
	divider: {
		borderTopColor: theme.colorCopyDarkDark,
		borderTopStyle: "solid",
		borderTop: 1,
		height: 1,
		width: "100%",
		marginBottom: 5,
	},
	flexWrap: {
		flexWrap: "wrap",
	},
	font10: {
		fontSize: 10,
	},
	font8: {
		fontSize: 8,
	},
	w5: {
		width: "5%",
	},
	w10: {
		width: "10%",
	},
	w125: {
		width: "12.5%",
	},
	w15: {
		width: "15%",
	},
	w20: {
		width: "20%",
	},
	w25: {
		width: "25%",
	},
	wSixth: {
		width: "16.666%",
	},
	w30: {
		width: "30%",
	},
	wThird: {
		width: "33.33%",
	},
	w35: {
		width: "35%",
	},
	w40: {
		width: "40%",
	},
	w50: {
		width: "50%",
	},
	w60: {
		width: "60%",
	},
	wTwoThirds: {
		width: "66.66%",
	},
	w70: {
		width: "70%",
	},
	w75: {
		width: "75%",
	},
	w80: {
		width: "80%",
	},
	w85: {
		width: "85%",
	},
	wFull: {
		width: "100%",
	},
	mt5: {
		marginTop: 5,
	},
	mt10: {
		marginTop: 10,
	},
	mt30: {
		marginTop: 30,
	},
	mb5: {
		marginBottom: 5,
	},
	mb10: {
		marginBottom: 10,
	},
	mb12: {
		marginBottom: 12,
	},
	ml3: {
		marginLeft: 3,
	},
	ml10: {
		marginLeft: 10,
	},
	my10: {
		marginVertical: 10,
	},
	mxAuto: {
		marginHorizontal: "auto",
	},
	p0: {
		padding: 0,
	},
	p1: {
		padding: 1,
	},
	p3: {
		padding: 3,
	},
	p5: {
		padding: 5,
	},
	flex1: {
		flex: 1,
	},
	underline: {
		borderColor: theme.colorCopyDarkDark,
		borderStyle: "solid",
		borderBottom: 1,
	},
	bullet: {
		marginLeft: 10,
		marginRight: 5,
		fontSize: 10,
	},
	alignCenter: {
		alignItems: "center",
	},
	alignStart: {
		alignItems: "flex-start",
	},
	justifyCenter: {
		justifyContent: "center",
	},
	textUnderline: {
		textDecoration: "underline",
	},
	bgGray: {
		backgroundColor: "#F5F5F5",
	},
});

export const fieldStyles = StyleSheet.create({
	fieldContainer: {
		flexDirection: "row",
		width: "100%",
		flexWrap: "wrap",
		gap: 0,
	},
	field: {
		borderColor: theme.colorCopyDarkDark,
		borderStyle: "solid",
		borderWidth: 1,
		color: theme.colorCopyDarkDark,
		paddingHorizontal: 3,
		minHeight: 30,
		borderRight: 0,
		borderBottom: 0,
	},
	fieldSmall: {
		minHeight: 20,
	},
	fieldHeaderContainer: {
		borderColor: theme.colorCopyDarkDark,
		borderStyle: "solid",
		borderWidth: 1,
		borderBottom: 0,
		paddingHorizontal: 3,
		paddingVertical: 1,
		width: "100%",
		backgroundColor: "#F5F5F5",
	},
	fieldHeader: {
		fontFamily: "Merriweather",
		fontSize: 12,
	},
	heading: {
		color: theme.colorPrimary,
		fontSize: 16,
		width: "100%",
		marginBottom: 10,
		fontFamily: "Merriweather",
		fontWeight: 700,
	},
	borderRight: {
		borderRight: 1,
	},
	borderBottom: {
		borderBottom: 1,
	},
	label: {
		fontSize: 8,
	},
	checkBoxContainer: {
		flexDirection: "row",
		gap: 10,
		padding: 3,
		minHeight: 20,
		borderRight: 1,
		height: "100%",
	},
	initials: {
		fontSize: 16,
	},
	signature: {
		width: 120,
		height: 48,
	},
});

export const ListItem = (props: {
	children: React.ReactNode;
	listLabel?: string;
	indent?: boolean;
}) => {
	return (
		<View style={{ ...pdfStyles.flexRow, alignItems: "flex-start" }}>
			<View
				style={{
					...pdfStyles.bullet,
					...(props.indent ? { marginLeft: 35 } : {}),
				}}
			>
				{props.listLabel ? (
					<Text style={{ ...pdfStyles.font10 }}>{props.listLabel}</Text>
				) : (
					<Text>{"\u2022" + " "}</Text>
				)}
			</View>
			<Text>{props.children}</Text>
		</View>
	);
};

export const displayFormattedDate = (date: string) => {
	if (date) {
		return (
			<Text>
				{new Date(date).toLocaleString("en-US", {
					month: "2-digit",
					day: "2-digit",
					year: "numeric",
				})}
			</Text>
		);
	} else return <></>;
};

export const FieldHeader = (props: { header: string; subheader?: string }) => {
	return (
		<View style={{ ...fieldStyles.fieldHeaderContainer }}>
			<Text style={{ ...fieldStyles.fieldHeader }}>{props.header}</Text>
			{props.subheader && <Text>{props.subheader}</Text>}
		</View>
	);
};

export const YesNoCheckbox = (props: {
	label: string;
	value: string;
	style?: any;
}) => {
	return (
		<PdfField
			label=""
			style={{
				...pdfStyles.wFull,
				...pdfStyles.p0,
				...fieldStyles.borderRight,
				minHeight: 20,
				...props.style,
			}}
		>
			<View
				style={{
					...pdfStyles.flexRow,
				}}
			>
				<View
					style={{
						...fieldStyles.checkBoxContainer,
					}}
				>
					<PdfCheckbox label="Yes" isChecked={props.value === "Yes"} />
					<PdfCheckbox label="No" isChecked={props.value === "No"} />
				</View>

				<Text
					style={{
						...pdfStyles.flexRow,
						...pdfStyles.flexWrap,
						...pdfStyles.flex1,
						...pdfStyles.p3,
					}}
				>
					{props.label}
				</Text>
			</View>
		</PdfField>
	);
};

export const PdfField = (props: PdfFieldProps) => {
	const { label, children, style, flexRow, font10 } = props;
	return (
		<View
			style={{
				...fieldStyles.field,
				...(flexRow ? { ...pdfStyles.flexRow, ...pdfStyles.gap10 } : {}),
				...style,
			}}
		>
			<Text
				style={{
					...fieldStyles.label,
					...(font10 ? { ...pdfStyles.font10 } : {}),
				}}
			>
				{label}
			</Text>
			{children}
		</View>
	);
};

export const checkBoxStyles = StyleSheet.create({
	checkboxContainer: {
		flexDirection: "row",
		gap: 3,
		fontSize: 10,
		// color: theme.colorPrimary,
		alignItems: "center",
	},
	checkboxSquare: {
		width: 8,
		height: 8,
	},
});

export const PdfCheckbox = (props: { isChecked?: boolean; label: string }) => {
	return (
		<View style={checkBoxStyles.checkboxContainer}>
			{props.isChecked ? (
				<Image style={checkBoxStyles.checkboxSquare} src={squareCheck} />
			) : (
				<Image style={checkBoxStyles.checkboxSquare} src={square} />
			)}
			<Text>{props.label}</Text>
		</View>
	);
};

const PdfPage = (props: PdfTemplateProps) => {
	const { children, heading, heading2, pageLabel, revisionLabel } = props;

	return (
		<Page size="A4" style={pdfStyles.page}>
			<View style={pdfStyles.header}>
				<View style={pdfStyles.container}>
					<Text style={pdfStyles.heading}>{heading}</Text>
					{heading2 && <Text style={pdfStyles.heading}>{heading2}</Text>}
				</View>

				<Image style={pdfStyles.logo} src={logo} />
			</View>
			<View>{children}</View>
			<View style={pdfStyles.footer}>
				<Text>{pageLabel}</Text>
				<Text>{revisionLabel}</Text>
			</View>
		</Page>
	);
};

export default PdfPage;

interface PdfTemplateProps {
	heading: string;
	heading2?: string;
	children: React.ReactNode;
	pageLabel: string;
	revisionLabel: string;
}

interface PdfFieldProps {
	label: string;
	children: React.ReactNode;
	style?: any;
	flexRow?: boolean;
	font10?: boolean;
}
