import Dropdown from "../../../components/FormFields/Dropdown";
import CheckBox from "../../../components/FormFields/CheckBox";
import TextField from "../../../components/FormFields/Textfield";
import Radio from "../../../components/FormFields/Radio";
import DateField from "../../../components/FormFields/DateField";
import Signature from "../../../components/FormFields/Signature";
import FileUpload from "../../../components/FormFields/FileUpload";
import Modal from "../../../components/Modal/Modal";
import Button, { ColorStyle } from "../../../components/Button/Button";
import NoticeBanner from "../../../components/NoticeBanner/NoticeBanner";
import * as Yup from "yup";
import FormGrid from "../../../components/FormGrid/FormGrid";
import { useFormikContext } from "formik";
import { AgentTypeOptions, USStates } from "../FormSchema/formInitialValues";
import { useEffect } from "react";
import { getNestedValue } from "../../../utils/getNestedValue";
import { useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import StyledList from "../../../components/Lists/Lists";

const requiredWarning = "This field is required.";

export const schema = {
	values: {
		affirmation: {
			applicantSignature: "",
			todaysDate: "",
			legalGuardian: "",
			legalGuardianRelationship: "",
		},
	},
	validation: Yup.object().shape({
		affirmation: Yup.object().shape({
			applicantSignature: Yup.string().required(requiredWarning),
			todaysDate: Yup.string()
				.required(requiredWarning)
				.test({
					name: "today",
					message: "Must select today's date",
					test(value, ctx) {
						if (value) {
							return new Date(value).getDate() === new Date().getDate();
						} else return true;
					},
				}),
		}),
	}),
};

const Affirmation = () => {
	const { values } = useFormikContext<any>();

	return (
		<>
			<h2>
				With my signature, I authorize the disclosure of my protected health
				information as described below:
			</h2>
			<StyledList>
				<ol>
					<li>
						Valid authorization to disclose nonpublic personal information
						pursuant to 13.1.3.14 NMAC shall be in written or electronic form
						separate from that used for any other purpose and shall contain all
						the following:
						<ol className="list-alpha">
							<li>
								The identity of the consumer or customer who is the subject of
								nonpublic personal information.
							</li>
							<li>
								A specific description of the types of nonpublic personal
								information to be disclosed.{" "}
							</li>
							<li>
								Specific descriptions of the parties to whom the licensee
								discloses nonpublic personal information, the purpose of the
								disclosure and how the information will be used.
							</li>
							<li>
								The signature of the consumer or customer who is the subject of
								the nonpublic personal information or the individual who is
								legally empowered to grant authority and the date signed; and
							</li>
							<li>
								Notice of the length of time for which the authorization is
								valid, and that the consumer or customer may revoke the
								authorization at any time and the procedure for making a
								revocation.
							</li>
						</ol>
					</li>

					<li>
						An authorization for the purposes of this rule shall specify a
						length of time for which the authorization shall remain valid, which
						in no event shall be more than twenty-four (24) months.
					</li>

					<li>
						A consumer or customer who is the subject of nonpublic personal
						information may revoke an authorization provided pursuant to this
						rule at any time, subject to the rights of an individual who acted
						in reliance on the authorization prior to notice of the revocation.
					</li>

					<li>
						A licensee shall retain the authorization or a copy thereof in the
						record of the individual who is the subject of nonpublic personal
						information.
					</li>
				</ol>
			</StyledList>

			<p>
				I further acknowledge that any person who knowingly presents a false or
				fraudulent claim for payment of a loss or benefit or knowingly presents
				false information in an application for insurance is guilty of a crime
				and may be subject to civil fines and criminal penalties.
			</p>

			<h2>
				My signature below authorizes disclosure of my protected health
				information and acknowledgement as described above. Application cannot
				be processed without signature.
			</h2>

			<Signature
				name="affirmation.applicantSignature"
				label="Signature of Applicant"
				signatureType="AffirmationApplication"
				required
			/>

			<DateField
				className="max-w-[300px]"
				name="affirmation.todaysDate"
				label="Today's Date"
				placeholder="Select Today's Date"
				required
			/>

			<p>
				A parent/legal guardian/personal representative must initial if the
				applicant is under 18 years of age or legally incompetent.
			</p>

			<FormGrid>
				<TextField
					type="text"
					name="affirmation.legalGuardian"
					label="Name of parent/legal guardian/personal representative"
					autoComplete="off"
					mobileExtraPadding
				/>
				<TextField
					type="text"
					name="affirmation.legalGuardianRelationship"
					label="Relationship to Applicant"
					autoComplete="off"
				/>
			</FormGrid>

			{values["paymentType"] === "Money Order or Check" && (
				<>
					<p className="leading-[1.5rem]">
						<strong>
							If paying premium by check or money order, you must MAIL the
							application and all attachments WITH the payment.
						</strong>
						<br />
						Please include the applicant's first and last name, along with the
						email address or phone number used to sign into this online
						application.
					</p>

					<p className="leading-[1.5rem]">
						<strong>Mail Premium Payments to:</strong>
						<br />
						New Mexico Medical Insurance Pool (NMMIP) <br />
						P.O. Box 27745 <br />
						Albuquerque, NM 87125-7745
					</p>
				</>
			)}
		</>
	);
};

export default Affirmation;
