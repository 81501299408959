import Start from "../SectionIntro/Start";
import Medicare from "../SectionIntro/Medicare";
import BasicInfo from "../Section1/BasicInfo";
import Newborn from "../SectionIntro/Newborn";
import Intro from "../SectionIntro/Intro";
import ApplicantInfo from "../Section1/ApplicantInfo";
import CoverageDetails from "../Section2/CoverageDetails";
import ProofOfEligibility from "../Section3/ProofOfEligibility";
import HIPAA from "../Section3/HIPAA";
import GeneralExclusions1 from "../Section3/GeneralExclusions1";
import GeneralExclusions2 from "../Section3/GeneralExclusions2";
import MedicalInformation from "../Section4/MedicalInformation";
import Physician from "../Section4/Physician";
import MedicalConditions from "../Section4/MedicalConditions";
import InsuranceAgent from "../Section5/InsuranceAgent";
import AgentInformation from "../Section5/AgentInformation";
import PremiumPayment from "../Section6/PremiumPayment";
import Documentation from "../SectionDocumentation/Documentation";
import Affirmation from "../Section7/Affirmation";
import Initials from "../Section7/Initials";
import Submission from "../Submission/Submission";

export const renderFormStep = (step: number) => {
	switch (step) {
		case 0:
			return <Intro />;
		case 1:
			return <Start />;
		case 2:
			return <Medicare />;
		case 3:
			return <Newborn />;
		case 4:
			return <BasicInfo />;
		case 5:
			return <ApplicantInfo />;
		case 6:
			return <ProofOfEligibility />;
		case 7:
			return <HIPAA />;
		case 8:
			return <GeneralExclusions1 />;
		case 9:
			return <GeneralExclusions2 />;
		case 10:
			return <CoverageDetails />;
		case 11:
			return <MedicalInformation />;
		case 12:
			return <Physician />;
		case 13:
			return <MedicalConditions />;
		case 14:
			return <InsuranceAgent />;
		case 15:
			return <AgentInformation />;
		case 16:
			return <PremiumPayment />;
		case 17:
			return <Documentation />;
		case 18:
			return <Initials />;
		case 19:
			return <Affirmation />;
		case 20:
			return <Submission />;
		default:
			return <div>Not Found</div>;
	}
};

export const steps: Step[] = [
	{
		heading: "Application for Coverage",
	},
	{
		heading: "Let's Start Your Healthcare Application",
		subHeading: "Answer a few questions and get you the coverage you need.",
	},
	{
		heading: "Medicare Eligibility",
	},
	{
		heading: "Newborn Coverage",
	},
	{
		heading: " Applicant Information",
		subHeading:
			"Fill in your individual information below. If multiple family members are applying for coverage, each member must complete a separate Application for Coverage.",
	},
	{
		heading: "Applicant Information",
		subHeading:
			"You must be a New Mexico resident and provide proof of residency documentation to be eligible for healthcare coverage from NMMIP.",
	},
	{
		heading: "Proof of Eligibility",
		subHeading:
			"To determine if you meet the eligibility criteria of NMMIP's guidelines select YES or NO for every question below and provide a rejection notice or quote for comparable insurance documentation. ",
	},
	{
		heading: "Eligibility Under Portability Criteria (HIPAA)",
		subHeading:
			"To determine if you meet the eligibility criteria of those established by the Health Insurance Portability and Accountability Act (HIPAA), you must answer yes to the following three questions and provide documentation of prior coverage.",
	},
	{
		heading: "General Exclusions",
		subHeading:
			"If you answer “yes” to any of the following questions, you may not be eligible for NMMIP coverage.",
	},
	{
		heading: "General Exclusions",
		subHeading:
			"If you answer “yes” to any of the following questions, you may not be eligible for NMMIP coverage.",
	},
	{
		heading: "Coverage Start Date & Deductible",
	},
	{
		heading: "Medical Information",
	},
	{
		heading: "Medical Information",
		subHeading:
			"If you have a Primary Care Physician (PCP) and/or Specialist, complete the information below.",
	},
	{
		heading: "Medical Information",
		subHeading:
			"Check any of the following medical conditions that you have. You may be eligible for additional services.",
	},
	{
		heading: "Agent, State Agency/Foundation, Third Party Sponsor",
		subHeading:
			"Insurance agents in your community can assist you in completing this application at no cost to you. Use the “Find a Broker” button below for a list of brokers.",
	},
	{
		heading: "Agent, State Agency/Foundation, Third Party Sponsor",
		subHeading:
			"Insurance agents in your community can assist you in completing this application at no cost to you. Use the “Find a Broker” button below for a list of brokers.",
	},
	{
		heading: "Premium Payment Information",
	},
	{
		heading: "Supporting Documentation",
	},
	{
		heading: "Affirmation, Understanding & Disclosure Authorization",
	},
	{
		heading: "Affirmation, Understanding & Disclosure Authorization",
	},
	{
		heading: "Thank you for Submitting your Application for Coverage",
	},
];

interface Step {
	heading: string;
	subHeading?: string;
}
