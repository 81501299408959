import Dropdown from "../../../components/FormFields/Dropdown";
import CheckBox from "../../../components/FormFields/CheckBox";
import TextField from "../../../components/FormFields/Textfield";
import Radio from "../../../components/FormFields/Radio";
import DateField from "../../../components/FormFields/DateField";
import Signature from "../../../components/FormFields/Signature";
import FileUpload from "../../../components/FormFields/FileUpload";
import Modal from "../../../components/Modal/Modal";
import Button, { ColorStyle } from "../../../components/Button/Button";
import NoticeBanner from "../../../components/NoticeBanner/NoticeBanner";
import * as Yup from "yup";
import FormGrid from "../../../components/FormGrid/FormGrid";
import { FieldArray, useFormikContext } from "formik";
import { useEffect } from "react";
import { getNestedValue } from "../../../utils/getNestedValue";
import { useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import styled, { css, useTheme } from "styled-components";
import StyledLegend, {
	BlueLegendHeading,
} from "../../../components/Legend/Legend";
import { useLocation } from "react-router-dom";
import { DeductibleAmounts } from "../../HealthCareCoverageForm/FormSchema/formInitialValues";
import StyledList from "../../../components/Lists/Lists";
import { CloseIcon } from "../../../components/Icons/Close";
import { FaPlusCircle } from "react-icons/fa";
import { FormSeparator } from "../../HealthCareCoverageForm/FormSchema/HealthcareForm";
import TextArea from "../../../components/FormFields/TextArea";
import React from "react";

const requiredWarning = "This field is required.";

const newIncomeSource = {
	description: "",
	amount: "",
};

export const newAdultIncomeSource = {
	lastName: "",
	firstName: "",
	middleInitial: "",
	homePhone: "",
	workPhone: "",
	cellPhone: "",
	email: "",
	incomeSources: [newIncomeSource, newIncomeSource, newIncomeSource],
	totalIncome: "",
	todaysDate: "",
	signature: "",
	applicantName: "",
};

export const schema = {
	values: {
		affidavitB: [newAdultIncomeSource],
	},
	validation: Yup.object().shape({
		affidavitB: Yup.array()
			.of(
				Yup.object().shape({
					lastName: Yup.string().required(requiredWarning),
					firstName: Yup.string().required(requiredWarning),
					incomeSources: Yup.array()
						// .of(
						// 	Yup.object({
						// 		description: Yup.string().required(requiredWarning),
						// 		amount: Yup.string().required(requiredWarning),
						// 	})
						// )
						.test({
							name: "oneRequired",
							test(value, ctx) {
								const { incomeSources } = ctx.parent;

								if (incomeSources && incomeSources.length > 0) {
									const firstIncomeSource = incomeSources[0];

									if (!firstIncomeSource.description)
										return ctx.createError({
											path: `${ctx.path}[0].description`,
											message: requiredWarning,
										});

									if (!firstIncomeSource.amount)
										return ctx.createError({
											path: `${ctx.path}[0].amount`,
											message: requiredWarning,
										});
								}

								return true;
							},
						})
						.min(1, "At least one income source is required."),
					todaysDate: Yup.string()
						.required(requiredWarning)
						.test({
							name: "today",
							message: "Must select today's date",
							test(value, ctx) {
								if (value) {
									return new Date(value).getDate() === new Date().getDate();
								} else return true;
							},
						}),
					signature: Yup.string().required(requiredWarning),
					applicantName: Yup.string().required(requiredWarning),
				})
			)
			.min(1, "At least one adult with other income source is required."),
	}),
};

const AffidavitB = () => {
	const { values, setFieldValue } = useFormikContext<any>();

	return (
		<>
			<p>
				You have indicated that an adult in your household has had income and
				was not required to file a Federal Tax Income Form.
			</p>
			<p>
				The individual who was not required to file a Federal Tax Income Form
				must complete and sign Other Income Source Affidavit B below.
			</p>

			<FieldArray
				name="affidavitB"
				render={(affidavitBHelper) => (
					<>
						{values.affidavitB && values.affidavitB.length > 0 ? (
							values.affidavitB.map(
								(newAffidavit: any, newAffidavitIndex: number) => (
									<React.Fragment key={`affidavitB.${newAffidavitIndex}.key`}>
										{newAffidavitIndex > 0 && <FormSeparator />}
										<h2>Basic Info</h2>

										<FormGrid>
											<TextField
												type="text"
												name={`affidavitB.${newAffidavitIndex}.lastName`}
												label="Last Name"
												required
											/>
											<TextField
												type="text"
												name={`affidavitB.${newAffidavitIndex}.firstName`}
												label="First Name"
												required
											/>
											<TextField
												className="sm:!max-w-[175px]"
												type="text"
												name={`affidavitB.${newAffidavitIndex}.middleInitial`}
												label="Middle Initial"
											/>
										</FormGrid>

										<h2>Contact Information</h2>

										<FormGrid>
											<TextField
												type="text"
												name={`affidavitB.${newAffidavitIndex}.homePhone`}
												label="Home Phone"
											/>
											<TextField
												type="text"
												name={`affidavitB.${newAffidavitIndex}.workPhone`}
												label="Work Phone"
											/>
											<TextField
												type="text"
												name={`affidavitB.${newAffidavitIndex}.cellPhone`}
												label="Cell Phone"
											/>
											<TextField
												className="!basis-[275px]"
												type="email"
												name={`affidavitB.${newAffidavitIndex}.email`}
												label="Email Address"
											/>
										</FormGrid>

										<h2>Other Income Sources</h2>
										<FieldArray
											name={`affidavitB.${newAffidavitIndex}.incomeSources`}
											render={(incomeSourceHelper) => {
												const incomeSourcesVal =
													values["affidavitB"][newAffidavitIndex].incomeSources;
												const totalIncome =
													incomeSourcesVal && incomeSourcesVal.length > 0
														? incomeSourcesVal.reduce(
																(amt: number, source: any) => {
																	return (amt += source.amount
																		? Number(source.amount)
																		: 0);
																},
																0
														  )
														: 0;

												return (
													<>
														{incomeSourcesVal && incomeSourcesVal.length > 0 ? (
															incomeSourcesVal.map(
																(
																	incomeSource: any,
																	incomeSourceIndex: number
																) => (
																	<FormGrid
																		key={`affidavitB.${newAffidavitIndex}.incomeSources.${incomeSourceIndex}.key`}
																	>
																		<TextArea
																			className="!flex-[1_1_350px]"
																			name={`affidavitB.${newAffidavitIndex}.incomeSources.${incomeSourceIndex}.description`}
																			label="Income Source Description for Household"
																			required
																			rows={3}
																		/>
																		<TextField
																			type="currency"
																			name={`affidavitB.${newAffidavitIndex}.incomeSources.${incomeSourceIndex}.amount`}
																			label="Income Amount"
																			required
																		/>

																		<div className="!flex-[0_1_250px]">
																			<Button
																				type="button"
																				onClick={() =>
																					incomeSourceHelper.remove(
																						incomeSourceIndex
																					)
																				}
																			>
																				Remove Income Source
																				<CloseIcon
																					className="!text-white"
																					aria-label={`Remove income source ${
																						incomeSourceIndex + 1
																					}`}
																				/>
																			</Button>
																		</div>
																	</FormGrid>
																)
															)
														) : (
															<></>
														)}

														<div>
															<span>Total</span>
															<TextField
																className="max-w-[300px] font-bold"
																type="currency"
																name={`affidavitB.${newAffidavitIndex}.totalIncome`}
																label="Total Income"
																required
																readOnly
																value={totalIncome}
															/>
														</div>
														<Button
															className="h-fit"
															type="button"
															onClick={() =>
																incomeSourceHelper.push(newIncomeSource)
															}
															buttonstyle={ColorStyle.Secondary}
														>
															Add Additional Income Source
														</Button>
													</>
												);
											}}
										/>
										<h2>Signature</h2>
										<p>
											By my signature, I swear or affirm that I am not required
											to file a Federal Income Tax return for calendar year
											2024, and that my income for that calendar year was as
											noted above.
										</p>
										<FormGrid>
											<FormGrid className="flex-col flex-1">
												<TextField
													className="!flex-none"
													type="text"
													name={`affidavitB.${newAffidavitIndex}.applicantName`}
													label="Name of Applicant"
													required
												/>

												<DateField
													name={`affidavitB.${newAffidavitIndex}.todaysDate`}
													label="Today's Date"
													placeholder="Select Today's Date"
													required
												/>
											</FormGrid>
											<Signature
												name={`affidavitB.${newAffidavitIndex}.signature`}
												label="Signature of Applicant"
												signatureType="AffidavitBSignature"
												required
											/>
										</FormGrid>

										{newAffidavitIndex !== 0 && (
											<Button
												type="button"
												onClick={() =>
													affidavitBHelper.remove(newAffidavitIndex)
												}
												buttonstyle={ColorStyle.Primary}
											>
												Remove This Affidavit{" "}
												<CloseIcon
													className="!text-white"
													aria-label={`Remove affidavit ${
														newAffidavitIndex + 1
													}`}
												/>
											</Button>
										)}
									</React.Fragment>
								)
							)
						) : (
							<></>
						)}
						<FormSeparator />
						<Button
							type="button"
							onClick={() => affidavitBHelper.push(newAdultIncomeSource)}
							buttonstyle={ColorStyle.Secondary}
						>
							Add Additional Other Income Source Affidavit B
							<FaPlusCircle className="!text-white" />
						</Button>
					</>
				)}
			/>
		</>
	);
};

export default AffidavitB;
