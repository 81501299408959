import styled, { css } from "styled-components";
import { ColorStyle } from "../Button/Button";
import { borders } from "../../theme";

const StyledBanner = styled.div<{ bannerstyle: ColorStyle }>`
	${(p) => css`
		color: ${(p) => p.theme.colorCopyLightLight};
		font-size: ${(p) => p.theme.pSize};
		font-weight: ${(p) => p.theme.fontDefaultWeight};
		padding: 10px 20px;
		border-radius: ${borders.fields.radius};
		line-height: 26px;

		span {
			font-size: ${(p) => p.theme.pSize};
		}

		${p.bannerstyle === ColorStyle.Primary &&
		css`
			background: ${(p) => p.theme.colorPrimary};
		`}

		${p.bannerstyle === ColorStyle.Secondary &&
		css`
			background: ${(p) => p.theme.colorSecondary};
		`}
	`};
`;

const NoticeBanner = (props: NoticeBannerProps) => {
	const { children, bannerstyle } = props;
	return <StyledBanner bannerstyle={bannerstyle}>{children}</StyledBanner>;
};

export default NoticeBanner;

interface NoticeBannerProps {
	bannerstyle: ColorStyle;
	children: React.ReactNode;
}
