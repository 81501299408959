import SignatureCanvas from "react-signature-canvas";
import styled, { css, useTheme } from "styled-components";
import { useEffect, useRef, useState } from "react";
import { borders } from "../../theme";
import { FieldType } from "./fieldTypes";
import { Asterisk, Error, StyledFieldWrapper } from "./Field";
import { useField, useFormikContext } from "formik";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import StyledLegend from "../Legend/Legend";
import { FaCalendarAlt } from "react-icons/fa";
import { getNestedValue } from "../../utils/getNestedValue";

import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

const DateWrapper = styled.div`
	${(p) =>
		css`
			position: relative;
			.calendar-icon {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				right: 15px;
				cursor: pointer;
			}
			.datepicker-input {
				width: 100%;
				cursor: pointer;
			}

			.calendar {
				position: absolute;
				top: calc(100% + 10px);
				z-index: 99999999;
			}
		`}
`;

const DateField = (props: DateFieldProps) => {
	const theme = useTheme();
	const [date, setDate] = useState<Date | null>(null);
	const {
		name,
		label,
		required,
		showLegend,
		placeholder,
		className,
		mobileExtraPadding,
	} = props;
	const { values, setFieldValue } = useFormikContext<any>();
	const [field, meta, helpers] = useField(props);
	const [showCalendar, setShowCalendar] = useState(false);
	const dateWrapperRef = useRef<HTMLDivElement | null>(null);

	const formikVal = getNestedValue(values, name);

	const handleDateChange = (newDate: Value) => {
		if (newDate instanceof Date) {
			setFieldValue(name, newDate);
			setShowCalendar(false);
		}
	};

	useEffect(() => {
		if (formikVal) {
			setDate(formikVal);
		} else setDate(null);
	}, [formikVal]);

	// hide calendar on blur
	useEffect(() => {
		function handleClickOutside(event: any) {
			if (
				dateWrapperRef.current &&
				!dateWrapperRef.current.contains(event.target)
			) {
				setShowCalendar(false);
			}
		}

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [dateWrapperRef]);

	return (
		<StyledFieldWrapper
			className={className}
			haserror={meta.touched && meta.error ? true : false}
			mobileExtraPadding={mobileExtraPadding}
		>
			{showLegend && (
				<StyledLegend>
					{label}
					{required && <Asterisk />}
				</StyledLegend>
			)}

			<DateWrapper ref={dateWrapperRef}>
				{/* <DatePicker
					className="datepicker"
					selected={date}
					onChange={(newDate: Date | null) => handleDateChange(newDate)}
					placeholderText={
						placeholder
							? required
								? placeholder + "*"
								: placeholder
							: required
							? "Select a Date" + "*"
							: "Select a Date"
					}
					aria-label={label}
					aria-invalid={meta.error && meta.touched ? true : undefined}
					aria-required={required}
				/> */}
				<input
					{...field}
					className="datepicker-input"
					type="text"
					placeholder={
						placeholder
							? required
								? placeholder + "*"
								: placeholder
							: required
							? "Select a Date" + "*"
							: "Select a Date"
					}
					aria-label={label}
					aria-invalid={meta.error && meta.touched ? true : undefined}
					aria-required={required}
					readOnly
					value={date ? new Date(date).toLocaleDateString("en-US") : undefined}
					onFocus={() => setShowCalendar(true)}
				/>
				<FaCalendarAlt
					className="calendar-icon"
					color={theme.colorSecondary}
					onClick={() => setShowCalendar(true)}
				/>
				{showCalendar && (
					<Calendar
						className="calendar"
						onChange={handleDateChange}
						value={date}
					/>
				)}
			</DateWrapper>

			<Error touched={meta.touched} error={meta.error} />
		</StyledFieldWrapper>
	);
};

export default DateField;

interface DateFieldProps extends FieldType {
	className?: string;
	onSelectDate?(date: Date): void;
	placeholder?: string;
	showLegend?: boolean;
	mobileExtraPadding?: boolean;
}
