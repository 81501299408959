import React from "react";

interface FormSelectionProps {
  verificationMethod: "phone" | "email";
  contactInfo: string;
}

const FormSelection: React.FC<FormSelectionProps> = ({
  verificationMethod,
  contactInfo,
}) => {
  return (
    <div className="text-center p-8 bg-green-100 rounded-lg">
      <h2 className="text-xl font-semibold text-green-800 mb-4">
        Verification Successful!
      </h2>
      <p className="text-green-700">
        {verificationMethod === "phone" ? "Phone Number" : "Email"} Verified:
      </p>
      <p className="text-green-900 font-mono text-lg mt-2">{contactInfo}</p>
    </div>
  );
};

export default FormSelection;
