import Dropdown from "../../../components/FormFields/Dropdown";
import CheckBox from "../../../components/FormFields/CheckBox";
import TextField from "../../../components/FormFields/Textfield";
import Radio from "../../../components/FormFields/Radio";
import DateField from "../../../components/FormFields/DateField";
import Signature from "../../../components/FormFields/Signature";
import FileUpload from "../../../components/FormFields/FileUpload";
import Modal from "../../../components/Modal/Modal";
import Button, { ColorStyle } from "../../../components/Button/Button";
import NoticeBanner from "../../../components/NoticeBanner/NoticeBanner";
import * as Yup from "yup";
import FormGrid from "../../../components/FormGrid/FormGrid";
import { useFormikContext } from "formik";
import { useEffect } from "react";
import { getNestedValue } from "../../../utils/getNestedValue";
import { useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import styled, { css } from "styled-components";
import {
  countyOptions,
  householdSizeOptions,
  incomeThresholdMap,
  povertyGuidelineOptions,
} from "../FormSchema/formInitialValues";
import StyledLegend, {
  BlueLegendHeading,
} from "../../../components/Legend/Legend";
import { useLocation } from "react-router-dom";
import { DeductibleAmounts } from "../../HealthCareCoverageForm/FormSchema/formInitialValues";

const requiredWarning = "This field is required.";

export const schema = {
  values: {
    deductible: "",
    lippPaymentSignature: "",
  },
  validation: Yup.object().shape({
    deductible: Yup.string().required(requiredWarning),
    lippPaymentSignature: Yup.string().required(requiredWarning),
  }),
};

const PremiumPaymentCert = () => {
  const { values, setFieldValue } = useFormikContext<any>();

  return (
    <>
      <FormGrid className="flex-col">
        <StyledLegend className={`!mb-0 !max-w-full`}>
          <BlueLegendHeading>
            Selected deductible amount for your coverage:
          </BlueLegendHeading>

          {values["povertyGuideline"] && (
            <p className="mb-[1rem] !font-normal">
              {values["povertyGuideline"]}
            </p>
          )}

          <Dropdown
            className="!max-w-[700px]"
            name="deductible"
            label="Select an Amount"
            options={DeductibleAmounts}
            required
          />
        </StyledLegend>

        <BlueLegendHeading className="!max-w-full !flex-none !mb-0">
          I certify that I, or a member of my family, will be paying my NMMIP
          coverage premiums.
        </BlueLegendHeading>
        <p className="!max-w-full !flex-none">
          If your premium is being paid by a third party who is not a family
          member, you are <span className="underline">not</span> eligible for
          LIPP.
        </p>
        <Signature
          name="lippPaymentSignature"
          label="Signature of Applicant"
          signatureType="lippPaymentSignature"
          required
        />
      </FormGrid>
    </>
  );
};

export default PremiumPaymentCert;
