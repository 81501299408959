import { Field, useField } from "formik";
import styled, { css } from "styled-components";
import { FieldType } from "./fieldTypes";
import { borders } from "../../theme";
import { useEffect } from "react";
import { CheckBoxLabel } from "./CheckBox";

const StyledAsterisk = styled.span`
	/* color: ${(p) => p.theme.colorSecondary}; */
`;

export const Asterisk = () => {
	return <StyledAsterisk>*</StyledAsterisk>;
};

const StyledError = styled.span`
	${(p) => css`
		padding: 15px 23px;
		background: #ffeff2;
		border: 2px solid ${p.theme.colorSecondary};
		border-radius: ${borders.fields.radius};
		color: ${p.theme.colorSecondary};
		font-weight: ${p.theme.fontSemiBold};
		font-size: ${p.theme.spanSize};
		margin-top: 10px;
		line-height: ${p.theme.spanSize};
	`}
`;

export const Error = (props: { touched: boolean; error?: string }) => {
	const { touched, error } = props;
	return touched && error ? <StyledError>{props.error}</StyledError> : null;
};

export const StyledFieldWrapper = styled.div<{
	type?: string;
	haserror?: boolean;
	hasvalue?: boolean;
	iscurrency?: boolean;
	placeholderColor?: string;
	mobileExtraPadding?: boolean;
}>`
	${(p) => css`
		position: relative;
		display: flex;
		flex-direction: column;

		input[type="text"],
		input[type="email"],
		input[type="number"],
		textarea,
		.dropdown {
			flex: 1;
			padding: 16px 24px;
			border: solid ${borders.fields.thickness}
				${p.haserror ? p.theme.colorSecondary : p.theme.colorPrimary};
			border-radius: ${borders.fields.radius};
			font-size: ${p.theme.spanSize};

			&::placeholder {
				color: ${p.theme.colorCopyDarkDark};
				white-space: pre-wrap;
			}

			&:focus-visible {
				transition: none;
				outline: 3px solid ${(p) => p.theme.colorSecondary};
				background: ${p.theme.colorBackgroundFocus};
			}

			@media only screen and (max-width: 475px) {
				${p.mobileExtraPadding &&
				css`
					padding-bottom: 32px !important;
				`}
			}
		}
		textarea {
			width: 100%;
		}
		.dropdown {
			-webkit-appearance: none;
			-moz-appearance: none;
			white-space: pre-wrap;
		}

		.caret {
			position: absolute;
			top: 50%;
			right: 15px;
			width: 10px;
			height: 10px;
			border-right: 3px solid ${p.theme.colorSecondary};
			border-top: 3px solid ${p.theme.colorSecondary};
			transform: translateY(-50%) rotate(135deg);
			pointer-events: none;
			z-index: 99;
		}

		${p.haserror &&
		css`
			${CheckBoxLabel}, .dropdown {
				border: solid 2px ${p.theme.colorSecondary};
			}
		`}

		${p.iscurrency &&
		p.hasvalue &&
		css`
			input {
				padding-left: 34px !important;
			}

			.dollar-sign {
				position: absolute;
				left: 24px;
				top: 12px;
			}
		`}

		@media only screen and (max-width: 375px) {
			input[type="text"],
			input[type="email"],
			input[type="number"],
			textarea,
			.dropdown {
				padding: 16px 12px;
			}
		}
	`};
`;

export const getPlaceholder = (
	label: string,
	required?: boolean,
	iscurrency?: boolean
) => {
	let placeholderLabel = label + ":";
	if (required) placeholderLabel += "*";
	if (iscurrency) placeholderLabel += " $";

	return placeholderLabel;
};

const FormikField = (props: FormikFieldProps) => {
	const {
		label,
		required,
		type,
		value,
		id,
		className,
		error,
		maxLength,
		autoComplete,
		disabled,
		mobileExtraPadding,
		readOnly,
	} = props;
	const [field, meta, helpers] = useField(props);
	const iscurrency = type === "currency";

	const isFalsyFieldVal =
		field.value === null || field.value === undefined || field.value === "";
	const isFalsyVal = value === null || value === undefined || value === "";
	const hasvalue = !isFalsyFieldVal || !isFalsyVal;

	return (
		<StyledFieldWrapper
			className={className}
			type={type}
			haserror={meta.touched && meta.error ? true : false}
			hasvalue={hasvalue}
			iscurrency={iscurrency}
			mobileExtraPadding={mobileExtraPadding}
		>
			<input
				id={id}
				type={iscurrency ? "number" : type}
				placeholder={getPlaceholder(label, required, iscurrency)}
				{...field}
				maxLength={maxLength}
				autoComplete={autoComplete}
				disabled={disabled}
				value={!isFalsyFieldVal ? field.value : !isFalsyVal ? value : undefined}
				aria-label={label}
				aria-invalid={meta.error && meta.touched ? true : undefined}
				aria-required={required}
				readOnly={readOnly}
			/>
			{iscurrency && hasvalue ? <span className="dollar-sign">$</span> : <></>}
			<Error touched={meta.touched} error={meta.error || error} />
		</StyledFieldWrapper>
	);
};

export default FormikField;

export interface FormikFieldProps extends FieldType {
	className?: string;
	id?: string;
	required?: boolean;
	maxLength?: number;
	autoComplete?: string;
	disabled?: boolean;
	mobileExtraPadding?: boolean;
	readOnly?: boolean;
}
