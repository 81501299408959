import React from "react";
import logo from "../../assets/NMMIP_logo.webp";
import GoogleTranslateWidget from "../GoogleTranslate/GoogleTranslateWidget";

const Header: React.FC = () => {
	return (
		<div>
			<GoogleTranslateWidget />
			<div
				id="form-header"
				className="flex items-center w-full py-6 flex-wrap gap-[1rem]"
			>
				<img
					src={logo}
					alt="NMMIP Logo"
					className="w-[185px] cursor-pointer"
					onClick={() => (window.location.href = "https://nmmip.org/")}
				/>

				<div id="download-link-container" className="ml-auto "></div>
			</div>
		</div>
	);
};

export default Header;
