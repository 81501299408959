// section intro
import { schema as introSchema } from "../SectionIntro/Intro";

// section 1
import { schema as applicantInfoSchema } from "../Section1/ApplicantInfo";

// section 2
import { schema as qualifyingConditionsSchema } from "../Section2/QualifyingConditions";
import { schema as qualifyingConditions2Schema } from "../Section2/QualifyingConditions2";

// section 3
import { schema as medicalInformationSchema } from "../Section3/MedicalInformation";
import { schema as physicianSchema } from "../Section3/Physician";
import { schema as medicalConditionsSchema } from "../Section3/MedicalConditions";

// section 4
import { schema as insuranceAgentSchema } from "../Section4/InsuranceAgent";
import { schema as agentInformationSchema } from "../Section4/AgentInformation";

// section 5
import { schema as coverageDetailsSchema } from "../Section5/CoverageDetails";

// section 6
import { schema as premiumPaymmentSchema } from "../Section6/PremiumPayment";

// section 7
import { schema as affirmationSchema } from "../Section7/Affirmation";

// Define schema for Medicare form fields
type Schema = {
	values: Record<string, any>;
	validation: any;
};

export const schemas: Record<string, Schema> = {
	applicantInfoSchema,
	qualifyingConditionsSchema,
	qualifyingConditions2Schema,
	medicalInformationSchema,
	physicianSchema,
	medicalConditionsSchema,
	insuranceAgentSchema,
	agentInformationSchema,
	coverageDetailsSchema,
	premiumPaymmentSchema,
	affirmationSchema,
};

export const medicareFormValues = {
	...applicantInfoSchema.values,
	...qualifyingConditionsSchema.values,
	...qualifyingConditions2Schema.values,
	...medicalInformationSchema.values,
	...physicianSchema.values,
	...medicalConditionsSchema.values,
	...insuranceAgentSchema.values,
	...agentInformationSchema.values,
	...coverageDetailsSchema.values,
	...premiumPaymmentSchema.values,
	...affirmationSchema.values,
};

export const medicareFormSchema = [
	introSchema.validation,
	applicantInfoSchema.validation,
	qualifyingConditionsSchema.validation,
	qualifyingConditions2Schema.validation,
	medicalInformationSchema.validation,
	physicianSchema.validation,
	medicalConditionsSchema.validation,
	insuranceAgentSchema.validation,
	agentInformationSchema.validation,
	coverageDetailsSchema.validation,
	premiumPaymmentSchema.validation,
	affirmationSchema.validation,
];
