import * as Yup from "yup";
import Radio from "../../../components/FormFields/Radio";
import FormGrid from "../../../components/FormGrid/FormGrid";
import Button from "../../../components/Button/Button";

export const schema = {
	values: {},
	validation: Yup.object().shape({}),
};

const Intro = () => {
	return (
		<>
			<p>
				To apply for New Mexico Medical Insurance Pool (NMMIP) coverage, you
				will need to complete and submit this Application for Coverage,
				supporting documents, and first premium payment. Each family member
				applying for coverage must complete a separate Application for Coverage.
				<br /> <br />
				Detailed information about benefits and premium rates is available at
				the buttons below.
			</p>
			<div className="flex gap-[1rem] flex-wrap">
				<Button
					type="button"
					href={"https://nmmip.org/members/summary-of-benefits/"}
				>
					View Benefits Information
				</Button>
				<Button type="button" href={"https://nmmip.org/rate-tables/"}>
					View Premium Rates Information
				</Button>
			</div>

			<p>
				Information you add to apply online is only stored here temporarily.
				After seven days, if you have not completed and submitted your
				application, you will need to start over. Once submitted, your
				application is sent to 90 Degree Benefits. For assistance, call
				1-866-306-1882 or email{" "}
				<a href="mailto:NMMIP_Eligibility@90degreebenefits.com">
					NMMIP_Eligibility@90degreebenefits.com.
				</a>
			</p>
		</>
	);
};

export default Intro;
