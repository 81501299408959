import Dropdown from "../../../components/FormFields/Dropdown";
import CheckBox from "../../../components/FormFields/CheckBox";
import TextField from "../../../components/FormFields/Textfield";
import Radio from "../../../components/FormFields/Radio";
import DateField from "../../../components/FormFields/DateField";
import Signature from "../../../components/FormFields/Signature";
import FileUpload from "../../../components/FormFields/FileUpload";
import Modal from "../../../components/Modal/Modal";
import Button, { ColorStyle } from "../../../components/Button/Button";
import NoticeBanner from "../../../components/NoticeBanner/NoticeBanner";
import * as Yup from "yup";
import FormGrid from "../../../components/FormGrid/FormGrid";
import { useFormikContext } from "formik";
import { useEffect } from "react";
import { getNestedValue } from "../../../utils/getNestedValue";
import { useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import styled, { css, useTheme } from "styled-components";
import StyledLegend, {
	BlueLegendHeading,
} from "../../../components/Legend/Legend";
import { useLocation } from "react-router-dom";
import { DeductibleAmounts } from "../../HealthCareCoverageForm/FormSchema/formInitialValues";
import StyledList from "../../../components/Lists/Lists";

const requiredWarning = "This field is required.";

export const schema = {
	values: {
		adjustedGrossIncome: "",
		excludedForeignIncome: "",
		nonTaxableSocialSecurityBenefits: "",
		taxExemptInterest: "",
		modifiedAdjustedGrossIncome: 0,
	},
	validation: Yup.object().shape({
		adjustedGrossIncome: Yup.string().required(requiredWarning),
		nonTaxableSocialSecurityBenefits: Yup.string().required(requiredWarning),
		taxExemptInterest: Yup.string().required(requiredWarning),
	}),
};

const Magi = () => {
	const { values, setFieldValue } = useFormikContext<any>();
	const theme = useTheme();

	const adjustedGrossIncome = values["adjustedGrossIncome"];
	const excludedForeignIncome = values["excludedForeignIncome"];
	const nonTaxableSocialSecurityBenefits =
		values["nonTaxableSocialSecurityBenefits"];
	const taxExemptInterest = values["taxExemptInterest"];

	useEffect(() => {
		const a = adjustedGrossIncome ? Number(adjustedGrossIncome) : 0;
		const b = excludedForeignIncome ? Number(excludedForeignIncome) : 0;
		const c = nonTaxableSocialSecurityBenefits
			? Number(nonTaxableSocialSecurityBenefits)
			: 0;
		const d = taxExemptInterest ? Number(taxExemptInterest) : 0;
		setFieldValue("modifiedAdjustedGrossIncome", a + b + c + d);
	}, [
		adjustedGrossIncome,
		excludedForeignIncome,
		nonTaxableSocialSecurityBenefits,
		taxExemptInterest,
	]);

	return (
		<>
			<p>
				For the verification process, NMMIP uses the Modified Adjusted Gross
				Income (MAGI) method to determine your eligibility for LIPP. MAGI is
				adjusted gross income (AGI) plus excluded foreign income, non-taxable
				Social Security benefits, and tax-exempt interest.
			</p>

			<StyledList>
				Complete the information below including yourself and these members of
				your household: <br />
				<br />
				<ul>
					<li>Include your spouse if you're legally married</li>
					<li>
						If you plan to claim someone as a tax dependent for the year you
						want NMMIP coverage, include them on your application. (If you won't
						claim that person as a tax dependent, do not include them.)
					</li>
					<li>
						Include your spouse and tax dependents even if they do not need
						health coverage
					</li>
				</ul>
			</StyledList>

			<Button type="button" href={"https://www.healthcare.gov/"}>
				Visit Healthcare.gov for More information
			</Button>

			<FormGrid className="flex-col">
				<StyledLegend className={`!mb-0 !max-w-[800px]`}>
					<BlueLegendHeading>
						<span className="underline">Adjusted Gross Income (AGI)</span>{" "}
						<br />
						As defined by the IRS, AGI is gross income minus adjustments to
						income.
					</BlueLegendHeading>
					<p className="font-normal mb-[1rem]">
						Enter the amount from your most recent 1040 tax form
					</p>

					<TextField
						className="max-w-[300px]"
						type="currency"
						name="adjustedGrossIncome"
						label="Enter Amount"
						required
					/>
				</StyledLegend>
				<StyledLegend className={`!mb-0 !max-w-[800px]`}>
					<BlueLegendHeading>
						<span className="underline">Excluded Foreign Income</span>
						<br />
						Foreign earned income excluded from taxation of individuals who live
						abroad.
					</BlueLegendHeading>
					<p className="font-normal mb-[1rem]">
						Enter the amount, if applicable
					</p>

					<TextField
						className="max-w-[300px]"
						type="currency"
						name="excludedForeignIncome"
						label="Enter Amount"
					/>
				</StyledLegend>
				<StyledLegend className={`!mb-0 !max-w-[800px]`}>
					<BlueLegendHeading>
						<span className="underline">
							Non-Taxable Social Security Benefits
						</span>
						<br />
						Social Security benefits not included in gross income.
					</BlueLegendHeading>
					<p className="font-normal mb-[1rem]">
						Enter the amount from your most recent 1040 tax form
					</p>

					<TextField
						className="max-w-[300px]"
						type="currency"
						name="nonTaxableSocialSecurityBenefits"
						label="Enter Amount"
						required
					/>
				</StyledLegend>
				<StyledLegend className={`!mb-0 !max-w-[800px]`}>
					<BlueLegendHeading>
						<span className="underline">Tax-Exempt Interest</span>
						<br />
						Interest income that is not subject to federal income tax.
					</BlueLegendHeading>
					<p className="font-normal mb-[1rem]">
						Enter the amount from your most recent 1040 tax form
					</p>

					<TextField
						className="max-w-[300px]"
						type="currency"
						name="taxExemptInterest"
						label="Enter Amount"
						required
					/>
				</StyledLegend>

				<div className="!max-w-[700px]">
					<BlueLegendHeading>
						Your Modified Adjusted Gross Income is:
					</BlueLegendHeading>
					<p className="mb-[1rem]">
						MAGI is determined by adding all amounts in the boxes above
					</p>

					<TextField
						className="!max-w-[350px] !flex-none font-bold"
						type="currency"
						name="modifiedAdjustedGrossIncome"
						label="Modified Adjusted Gross Income"
						required
						readOnly
					/>
				</div>
			</FormGrid>
		</>
	);
};

export default Magi;
