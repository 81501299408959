import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

interface ProtectedRouteProps {
	children: React.ReactNode;
}

const ProtectedRoute = (props: ProtectedRouteProps) => {
	const { isAuthenticated } = useAuth();
	const location = useLocation();

	if (!isAuthenticated) {
		// Redirect them to the /login page if not logged in
		return <Navigate to="/login" state={{ from: location }} replace />;
	}

	return <>{props.children}</>;
};

export default ProtectedRoute;
