import * as Yup from "yup";
import Radio from "../../../components/FormFields/Radio";
import FormGrid from "../../../components/FormGrid/FormGrid";
import { useEffect } from "react";
import { useFormikContext } from "formik";

const requiredWarning = "This field is required.";

export const schema = {
	values: {
		isHearingImpaired: "",
		preferredLanguage: "",
	},
	validation: Yup.object().shape({
		isHearingImpaired: Yup.string().required(requiredWarning),
		preferredLanguage: Yup.string().required(requiredWarning),
	}),
};

const Start = () => {
	const { values } = useFormikContext<any>();

	return (
		<FormGrid className="!gap-x-[5rem]">
			<Radio
				className="max-w-[475px]"
				name="isHearingImpaired"
				label="Are you hearing impaired?"
				options={[{ label: "Yes" }, { label: "No" }]}
				required
			/>
			<Radio
				className="max-w-[475px]"
				name="preferredLanguage"
				label="What is your preferred language? "
				options={[{ label: "English" }, { label: "Spanish" }]}
				required
			/>
		</FormGrid>
	);
};

export default Start;
