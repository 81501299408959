import styled, { css, useTheme } from "styled-components";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { borders } from "../../theme";
import { FieldType } from "./fieldTypes";
import { Asterisk, Error, StyledFieldWrapper } from "./Field";
import { useField, useFormikContext } from "formik";
import { FaCloudUploadAlt, FaTimesCircle } from "react-icons/fa";
import Button from "../Button/Button";
import { FileDrop } from "react-file-drop";
import { getNestedValue } from "../../utils/getNestedValue";
import axios from "axios";
import { useFormContext } from "../FormContext/FormContext";

const FileDropWrapper = styled.div`
	${(p) =>
		css`
			border: dashed 2px ${p.theme.colorCopyBlue};
			.file-drop {
				position: relative;
				height: 300px;
				width: 100%;
				background: #f8f8ff;
				cursor: pointer;

				& > .file-drop-target {
					position: absolute;
					top: 0;
					left: 0;
					height: 100%;
					width: 100%;
					border-radius: 2px;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					align-content: center;
					text-align: center;
				}

				& > .file-drop-target.file-drop-dragging-over-target {
					background-color: ${p.theme.colorFieldSelected};
				}
				.upload-text {
					font-weight: ${p.theme.fontSemiBold};
					margin-bottom: 1rem;
					.browse {
						text-decoration: underline;
						color: ${p.theme.colorCopyBlue};
					}
				}
			}
		`}
`;

export const UploadedFile = styled.div`
	${(p) =>
		css`
			position: relative;
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			padding: 0.5rem 1rem;
			border: solid ${borders.fields.thickness} ${p.theme.colorSecondary};
			border-radius: ${borders.fields.radius};
			font-size: ${p.theme.spanSize};
			background: ${p.theme.colorBackgroundLightLight};

			.filename {
				overflow-wrap: anywhere;
				max-width: 90%;
			}

			& > .delete-file {
				color: ${p.theme.colorPrimary};
				width: 20px;
				height: 20px;
				cursor: pointer;
			}
		`};
`;

const ErrMessage = styled.span`
	${(p) =>
		css`
			color: ${p.theme.colorSecondary};
		`}
`;

const uploadFile = async (
	file: File,
	formId: string,
	documentationType: string
) => {
	const formData = new FormData();
	formData.append("file", file);
	formData.append("formId", formId);
	formData.append("documentationType", documentationType);

	try {
		const response = await axios.post(`/api/files/upload`, formData, {
			headers: { "Content-Type": "multipart/form-data" },
		});
		return response.data.filePath;
	} catch (error) {
		console.error("Error uploading file:", error);
		throw error;
	}
};

const fetchFiles = async (formId: string, documentationType: string) => {
	try {
		const response = await axios.get(
			`/api/files/${formId}/${documentationType}`
		);
		return response.data.files;
	} catch (error) {
		console.error("Error fetching files:", error);
		throw error;
	}
};

const deleteFile = async (
	formId: string,
	documentationType: string,
	fileName: string
) => {
	try {
		await axios.delete(`/api/files/${formId}/${documentationType}/${fileName}`);
	} catch (error) {
		console.error("Error deleting file:", error);
		throw error;
	}
};

// File List component showing uploaded files and allowing deletion
export const UploadedFileList = (props: {
	fieldName: string;
	formId: string;
	documentationType: string;
}) => {
	const { values, setFieldValue } = useFormikContext<any>();
	const formikVal = getNestedValue(values, props.fieldName);

	const handleDelete = async (id: string, fileName: string) => {
		try {
			await deleteFile(props.formId, props.documentationType, fileName); // Delete from S3
			const updatedFiles = formikVal.filter((file: FileObj) => file.id !== id); // Remove from UI
			setFieldValue(props.fieldName, updatedFiles);
		} catch (error) {
			console.error("Failed to delete file:", error);
		}
	};

	return formikVal && Array.isArray(formikVal) && formikVal.length > 0 ? (
		<div className="flex flex-col gap-[1rem]">
			{formikVal.map((file: FileObj, i: number) => (
				<UploadedFile key={file.id}>
					<span className="filename">{file.file.name || "Unknown File"}</span>
					<FaTimesCircle
						className="delete-file"
						onClick={() =>
							handleDelete(file.id, file.file.name || "Unknown File")
						}
					/>
				</UploadedFile>
			))}
		</div>
	) : null;
};

// Main File Upload component
const FileUpload = (props: FileUploadProps) => {
	const theme = useTheme();
	const { name, documentationType, label, required } = props;
	const { values, setFieldValue } = useFormikContext<any>();
	const [field, meta, helpers] = useField(props);
	const fileInputRef = useRef<HTMLInputElement | null>(null);
	const acceptedFileTypes = ["application/pdf", "image/jpeg", "image/png"];
	const [fileErr, setFileErr] = useState<string[]>([]);
	const { formId } = useFormContext(); // Retrieve formId from context

	const formikVal = getNestedValue(values, name);

	// files uploaded to cloud bucket
	const [uploadedFiles, setUploadedFiles] = useState<FileObj[]>([]);

	// Handle file upload to S3
	const handleFileUpload = async (files: FileList | null) => {
		if (files) {
			Array.from(files).forEach(async (file) => {
				try {
					const filePath = await uploadFile(file, formId, documentationType!);
					setUploadedFiles((prev) => [...prev, { id: filePath, file }]);
					setFieldValue(name, [...uploadedFiles, { id: filePath, file }]); // Update formik field
				} catch (error) {
					console.error("Error uploading file:", error);
				}
			});
		}
	};

	// Trigger file upload when files are dropped
	const handleDrop = (files: FileList | null) => {
		if (files) {
			handleFileUpload(files);
		}
	};

	// Trigger file input when drop area is clicked
	const onTargetClick = () => {
		if (fileInputRef.current) fileInputRef.current.click();
	};

	const onFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { files } = event.target;
		handleDrop(files);
	};

	// hide doc upload error after 3 seconds
	useEffect(() => {
		if (fileErr.length > 0) {
			let timerId = setTimeout(() => {
				setFileErr([]);
			}, 3000);
			return () => clearTimeout(timerId);
		}
	}, [fileErr]);

	// Fetch existing files on mount
	useEffect(() => {
		const loadUploadedFiles = async () => {
			try {
				const files: string[] = await fetchFiles(formId, documentationType!); // Ensure files are typed as string[]
				setUploadedFiles(
					files.map((file: string) => ({
						id: file, // Assuming the file path or name serves as the ID
						file: { name: file.split("/").pop() || "Unknown" }, // Extract the file name
					}))
				);
			} catch (error) {
				console.error("Failed to load uploaded files:", error);
			}
		};
		loadUploadedFiles();
	}, [formId, documentationType]);

	// Keep formik props and uploaded files in sync
	useEffect(() => {
		setFieldValue(name, uploadedFiles);
	}, [uploadedFiles]);

	useEffect(() => {
		if (formikVal && formikVal.length > 0) {
			setUploadedFiles(formikVal);
		}
	}, [formikVal]);

	return (
		<StyledFieldWrapper
			className="w-full min-w-[350px] max-w-[90vw] mx-auto"
			haserror={meta.touched && meta.error ? true : false}
		>
			<FileDropWrapper>
				<FileDrop onDrop={handleDrop} onTargetClick={onTargetClick}>
					<FaCloudUploadAlt
						className="w-[100px] h-[100px] mb-[1rem]"
						color={theme.colorCopyBlue}
					/>
					<span className="upload-text">
						Drag & drop files or <strong className="browse">Browse</strong>
					</span>
					<span>Supported Formats: JPEG, PNG, PDF</span>
				</FileDrop>
				<input
					onChange={onFileInputChange}
					ref={fileInputRef}
					type="file"
					className="hidden"
					accept=".jpeg, .jpg, .png, .pdf"
					aria-label="Upload Files"
				/>
			</FileDropWrapper>

			{formikVal && Array.isArray(formikVal) && formikVal.length > 0 && (
				<span className="text-center font-semibold mt-[3rem] mb-[1rem]">
					Uploaded
				</span>
			)}

			<UploadedFileList
				fieldName={name}
				formId={formId}
				documentationType={documentationType!}
			/>

			{fileErr.map((err, i) => (
				<ErrMessage key={`${err}.${i}`}>{err}</ErrMessage>
			))}

			<Error touched={meta.touched} error={meta.error} />
		</StyledFieldWrapper>
	);
};

export default FileUpload;

// Add documentationType prop to FileUploadProps
interface FileUploadProps extends FieldType {
	documentationType?: string;
}

export interface FileObj {
	id: string;
	file: Partial<Pick<File, "name">>;
}
