import Dropdown from "../../../components/FormFields/Dropdown";
import CheckBox from "../../../components/FormFields/CheckBox";
import TextField from "../../../components/FormFields/Textfield";
import Radio from "../../../components/FormFields/Radio";
import DateField from "../../../components/FormFields/DateField";
import Signature from "../../../components/FormFields/Signature";
import FileUpload, {
	UploadedFileList,
} from "../../../components/FormFields/FileUpload";
import Modal from "../../../components/Modal/Modal";
import Button, { ColorStyle } from "../../../components/Button/Button";
import NoticeBanner from "../../../components/NoticeBanner/NoticeBanner";
import * as Yup from "yup";
import FormGrid from "../../../components/FormGrid/FormGrid";
import { useFormikContext } from "formik";
import { getNestedValue } from "../../../utils/getNestedValue";
import { useFormContext } from "../../../components/FormContext/FormContext";

const requiredWarning = "This field is required.";

export const schema = {
	values: {
		applicant: {
			firstName: "",
			lastName: "",
			middleInitial: "",
			homePhone: "",
			workPhone: "",
			cellPhone: "",
			email: "",
			nmmipID: "",
		},
	},
	validation: Yup.object().shape({
		applicant: Yup.object().shape({
			lastName: Yup.string().required(requiredWarning),
			firstName: Yup.string().required(requiredWarning),
			// Custom validation to ensure at least one phone number is provided
			atLeastOnePhoneNumberIsRequired: Yup.mixed().test({
				name: "atLeastOnePhoneNumber",
				message: "At least one phone number is required.",
				test(value, ctx) {
					const { homePhone, workPhone, cellPhone } = ctx.parent;
					return homePhone || workPhone || cellPhone;
				},
			}),
			email: Yup.string().email("Invalid email format"),
		}),
	}),
};

const BasicInfo = () => {
	const { errors, values } = useFormikContext<any>();
	const phoneErr = (errors as PartialErrors).applicant
		?.atLeastOnePhoneNumberIsRequired;

	return (
		<>
			<h2>Basic Information</h2>
			<FormGrid>
				<TextField
					type="text"
					name="applicant.lastName"
					label="Last Name"
					required
				/>
				<TextField
					type="text"
					name="applicant.firstName"
					label="First Name"
					required
				/>
				<TextField
					className="sm:!max-w-[175px]"
					type="text"
					name="applicant.middleInitial"
					label="Middle Initial"
				/>
				<TextField
					className="!basis-[275px]"
					type="text"
					name="applicant.nmmipID"
					label="NMMIP Member ID (if applicable)"
				/>
			</FormGrid>

			<h2>Contact Information</h2>

			<FormGrid>
				<TextField
					type="text"
					name="applicant.homePhone"
					label="Home Phone"
					error={phoneErr}
				/>
				<TextField
					type="text"
					name="applicant.workPhone"
					label="Work Phone"
					error={phoneErr}
				/>
				<TextField
					type="text"
					name="applicant.cellPhone"
					label="Cell Phone"
					error={phoneErr}
				/>
				<TextField
					className="!basis-[275px]"
					type="email"
					name="applicant.email"
					label="Email Address"
				/>
			</FormGrid>
		</>
	);
};

export default BasicInfo;

interface PartialErrors {
	applicant?: {
		atLeastOnePhoneNumberIsRequired?: string;
	};
}
